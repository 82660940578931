import '../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { translate } from '../../../../common/language/translations';
import { UserConfig } from '../../../types';
import { editAccessOptions, editFokusAccessOptions } from '../../../static/propertyValues';
import { getAccessIcon } from '../../../utils/iconUtils';
import { useState } from 'react';
import { updateUserConfig } from '../../../services/firestore/users';
import { IconButton } from '../../controls/buttons/IconButton';
import { TextButton } from '../../controls/buttons/TextButton';
import { OptionButton } from '../../controls/buttons/OptionButton';


interface AccountModalProps {
  user: UserConfig | undefined;
  closeModal: () => void;
  isFokusAccount?: boolean;
  isMobile?: boolean;
}

export const AccountModal: React.FC<AccountModalProps> = ({ user, closeModal, isFokusAccount, isMobile }) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);

  const { openTextModal } = useOpenGlobalModal();

  const [selectedAccess, setSelectedAccess] = useState<string | undefined>(user?.access);


  const confirmEdits = () => {
    if (user && selectedAccess && user.access !== selectedAccess) {
      updateUserConfig(user?.email, 'access', selectedAccess, 'AccessEdited', currentUser);
      closeModal();
    }
  };

  return (
    <div className='modal-root-container account-modal-root'>

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
        isMobile={isMobile}
      />

      <div className='modal-root-title'>
        {user ? translate('editAccessFor', userConfig?.language) + ' ' + user.name : ''}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      <div className='modal-content-container'>
        <div className='modal-option-column' style={{ marginTop: 30 }}>
          {(isFokusAccount ? editFokusAccessOptions : editAccessOptions).map(access => {

            const icon = user ? getAccessIcon(access, 22) : undefined;

            return (
              <div
                key={access}
                style={{ position: 'relative' }}
              >
                <IconButton
                  icon={<InfoOutlinedIcon style={{ fontSize: 16 }} />}
                  onClick={() => openTextModal(
                    translate(access + 'Access', userConfig?.language),
                    translate(access + 'AccessInfo', userConfig?.language),
                    { infoType: 'infoIcon', titleKey: access + 'Access' },
                  )}
                  size={24}
                  style={{ position: 'absolute', left: -40 }}
                  isMobile={isMobile}
                />

                <OptionButton
                  onClick={() => setSelectedAccess(access)}
                  option={access + 'Access'}
                  language={userConfig?.language}
                  optionIsSelected={access === selectedAccess}
                  icons={{ leftIcon: icon }}
                  style={{ width: 200 }}
                />
              </div>
            );
          })}
        </div>

        <div style={{ margin: 'auto', padding: '20px 0px' }}>
          <TextButton
            onClick={confirmEdits}
            text={translate('confirmNewAccess', userConfig?.language)}
            icon={<CheckIcon style={{ fontSize: 22 }} />}
            disabled={selectedAccess === user?.access}
            isMobile={isMobile}
          />
        </div>
      </div>

    </div>
  );
};
