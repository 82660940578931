import '../tables.css';

import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import { PlayerOverview } from '../../../types';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { getNavigationWidth } from '../../controls/navigation/Navigation';
import { debounce } from '../../../utils/utils';
import { playerTableChapterSize, playerTablePageSize } from '../../../views/scouting/Scouting';
import { findPlayerTableColumns, findPlayerTableColumnsWidth, findPlayerTablePadding } from './findPlayerColumns';
import { translate } from '../../../../common/language/translations';
import { PlayerInfoCell, PlayerPaginationCell } from '../cells';


interface FindPlayerTableProps {
  data: PlayerOverview[];

  // if not provided, the table represents the selected player
  searchTableProps?: {
    isLoading: boolean;
    handleSearchButtonPressed: (isUserInitiated: boolean, isNewSearch: boolean, nextChapter?: number) => Promise<void>;
    currentModuloPage: number;
    currentChapter: number;
    totalHits: number;
    handleChangeCurrentChapter: (isIncrement: boolean) => void;
    handlePlayerClick: (player: PlayerOverview) => void;
  }
}

export const FindPlayerTable: React.FC<FindPlayerTableProps> = ({
  data,
  searchTableProps,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { width } = useWindowSize();


  const handleRowClick = (playerOverview: PlayerOverview) => {
    if (searchTableProps?.handlePlayerClick) {
      searchTableProps.handlePlayerClick(playerOverview);
    }
  };


  const checkScrollPosition = async () => {
    if (!tableContainerRef.current || !searchTableProps) return;

    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
    const scrollPosition = scrollTop / (scrollHeight - clientHeight);
    const threshold = 0.4 + searchTableProps.currentModuloPage * 0.1;

    const isMoreDataToFetch = ((searchTableProps.currentChapter * playerTablePageSize * playerTableChapterSize) + data.length) < searchTableProps.totalHits;

    if (scrollPosition > threshold && !searchTableProps.isLoading && searchTableProps.currentModuloPage < 4 && isMoreDataToFetch) {
      await searchTableProps.handleSearchButtonPressed(false, false);
    }
  };


  useEffect(() => {
    if (searchTableProps && playerTableChapterSize > 1) {
      const [handleScroll, cancelHandleScroll] = debounce(() => checkScrollPosition(), 150);

      const tableContainer = tableContainerRef.current;
      if (tableContainer) {
        tableContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (tableContainer) {
          tableContainer.removeEventListener('scroll', handleScroll);
        }
        cancelHandleScroll();
      };
    }
  }, [searchTableProps?.isLoading]); // eslint-disable-line react-hooks/exhaustive-deps


  const tableWidth = width - 24 - getNavigationWidth(userConfig);
  const findPlayerTableStickyColumnWidth = 340;
  const findPlayerTableNonStickyWidth = tableWidth - findPlayerTableStickyColumnWidth;
  const findPlayerTableNonStickyMinWidth = findPlayerTableColumnsWidth + findPlayerTablePadding;
  const widthUnit = Math.max(findPlayerTableNonStickyWidth / findPlayerTableNonStickyMinWidth, 1);


  return (
    <div
      ref={tableContainerRef}
      className='table-container player-table-container'
      style={{ overflowX: widthUnit <= 1 ? 'auto' : 'hidden' }}
    >

      {/* Header */}
      <div className='table-header find-player-table-header find-player-table-header-background'>

        <div
          className='table-sticky-left find-player-table-header-background player-table-header-cell-with-border'
          style={{ width: findPlayerTableStickyColumnWidth }}
        >
          {!searchTableProps && (
            <div className='flex-row align-center' style={{ height: '100%', paddingLeft: 16 }}>
              {translate('selectedPlayer', userConfig?.language)}
            </div>
          )}

          {searchTableProps && (
            <PlayerPaginationCell
              totalHits={searchTableProps.totalHits}
              currentChapter={searchTableProps.currentChapter}
              hitsPerChapter={playerTablePageSize * playerTableChapterSize}
              handleChangeCurrentChapter={searchTableProps.handleChangeCurrentChapter}
              language={userConfig?.language}
            />
          )}
        </div>

        {findPlayerTableColumns.map(column => {
          return (
            <div
              key={column.key}
              className='table-cell'
              style={{ width: column.width * widthUnit }}
            >
              <div style={{ paddingRight: column.key === 'club' ? findPlayerTablePadding : 0 }}>
                {translate(column.key, userConfig?.language)}
              </div>
            </div>
          );
        })}
      </div>


      {/* Body */}
      <div>
        {data.map((row, rowIndex) => {
          return (
            <div
              key={rowIndex}
              className={
                'table-row player-table-row'
                + (rowIndex % 2 === 0 ? ' player-table-row-even' : ' player-table-row-odd')
                + (!searchTableProps ? ' player-table-row-even-hover-disabled' : '')
              }
              onClick={() => handleRowClick(row)}
            >

              <div
                className={
                  'table-sticky-left player-table-sticky-cell player-table-body-cell-with-border'
                  + (rowIndex % 2 === 0 ? ' player-table-sticky-cell-even' : ' player-table-sticky-cell-odd')
                }
                style={{ width: findPlayerTableStickyColumnWidth }}
              >
                <PlayerInfoCell
                  playerOverview={row}
                  maxPlayerNameLength={26}
                  language={userConfig?.language}
                  showClubInfo={false}
                  showTeamIcon={false}
                />
              </div>

              {findPlayerTableColumns.map(column => {
                return (
                  <div
                    key={column.key}
                    className='table-cell'
                    style={{ width: column.width * widthUnit }}
                  >
                    {column.cell({ row, language: userConfig?.language })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
