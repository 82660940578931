import '../tables.css';

import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';

import { careerTableColumns, careerTableColumnsWidth, careerTablePadding } from './careerColumns';
import { CareerIterationEntry } from '../../playerView/PlayerViewCareer';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { getNavigationWidth } from '../../controls/navigation/Navigation';
import { translate } from '../../../../common/language/translations';
import { Toggle } from '../../controls/input/Toggle';
import { updateSeasonStatsToggles } from '../../../services/firestore/userSettings';
import { CareerTableNationalCell, CareerTableSeasonCell } from '../cells';


interface CareerTableProps {
  data: CareerIterationEntry[];
  isNationalTeam: boolean;
}

export const CareerTable: React.FC<CareerTableProps> = ({ data, isNationalTeam }) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);

  const seasonStatsToggles = useMemo(() => userSettings?.seasonStatsToggles ?? {}, [userSettings?.seasonStatsToggles]);

  const { width } = useWindowSize();
  const tableWidth = width - 24 - getNavigationWidth(userConfig);
  const careerTableStickyColumnWidth = 330;
  const careerTableNonStickyWidth = tableWidth - careerTableStickyColumnWidth;
  const fcareerTableNonStickyMinWidth = careerTableColumnsWidth + careerTablePadding;
  const widthUnit = Math.max(careerTableNonStickyWidth / fcareerTableNonStickyMinWidth, 1);


  return (
    <div
      className='table-container season-table-container'
      style={{ position: 'absolute', overflowX: widthUnit <= 1 ? 'auto' : 'hidden' }}
    >

      {/* Header */}
      <div className='table-header career-table-header season-table-header-background'>

        <div
          className='table-sticky-left season-table-header-background career-table-cell-with-border season-table-sticky-header-cell'
          style={{ width: careerTableStickyColumnWidth, paddingBottom: 0, paddingRight: 16 }}
        >
          {translate(isNationalTeam ? 'nationalTeamTitle' : 'clubTeamTitle', userConfig?.language)}

          {userConfig && !isNationalTeam && (
            <div
              className='flex-row align-center season-table-sticky-header-cell-toggle-text'
              title={translate('showOnlyDomesticLeagues', userConfig?.language)}
            >
              {translate('leagues', userConfig?.language)}
              <div className='table-header-toggle'>
                <Toggle
                  isToggled={seasonStatsToggles.hideCareerCups}
                  setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                    { 'hideCareerCups': value },
                    userConfig.email,
                    userConfig.club,
                    currentUser
                  )}
                  isSmall={true}
                />
              </div>
            </div>
          )}
        </div>

        {careerTableColumns.map(column => {
          return (
            <div
              key={column.key}
              className='table-cell'
              style={{ width: column.width * widthUnit }}
            >
              <div style={{ paddingRight: column.key === 'assists' ? careerTablePadding : 0 }}>
                {translate(column.key, userConfig?.language)}
              </div>
            </div>
          );
        })}
      </div>


      {/* Body */}
      <div>
        {data.map((row, rowIndex) => {
          return (
            <div
              key={rowIndex}
              className={'table-row no-hover season-table-row' + (rowIndex % 2 === 0 ? ' season-table-row-even' : ' season-table-row-odd')}
              style={{ height: isNationalTeam ? 37 : 50 }}
            >

              <div
                className={
                  'table-sticky-left career-table-cell-with-border'
                  + (rowIndex % 2 === 0 ? ' season-table-sticky-cell-even' : ' season-table-sticky-cell-odd')
                }
                style={{ width: careerTableStickyColumnWidth }}
              >
                {isNationalTeam && <CareerTableNationalCell row={row} />}
                {!isNationalTeam && <CareerTableSeasonCell row={row} />}
              </div>


              {careerTableColumns.map(column => {
                return (
                  <div
                    key={column.key}
                    className='table-cell'
                    style={{ width: column.width * widthUnit }}
                  >
                    {column.cell({ row, language: userConfig?.language })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
