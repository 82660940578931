
import {
  OwnTeamTableContractCell,
  OwnTeamTableAgeCell,
  OwnTeamTableCountryCell,
  OwnTeamTableEconomicCell,
  OwnTeamTableLocalityCell,
  OwnTeamTableRoleCell
} from '../cells';

import { StringToAnyMap } from '../../../types';
import { getCurrencyExtension } from '../utils';
import { translate } from '../../../../common/language/translations';


interface OwnTeamTableColumn {
  key: string;
  property: string;
  width: number;
  title?: string;
  needsEconomyAccess?: boolean;
  cell: (row: StringToAnyMap) => JSX.Element;
}


export const ownTeamTableBaseColumnsWidth = 565;
export const ownTeamTableEconomyColumnsWidth = 325;
export const ownTeamTablePadding = 20;

export const getOwnTeamTableColumns = (
  hasEconomyAccess: boolean,
  clubCountryCode: string,
  clubLightColor: string,
  isAcademyTable: boolean,
  language: string,
): OwnTeamTableColumn[] => {

  const ownTeamColumns: OwnTeamTableColumn[] = [
    {
      key: 'age',
      property: 'birth_date',
      width: 90,
      cell: (row) => (
        <OwnTeamTableAgeCell row={row} language={language} />
      ),
    },
    {
      key: 'contract',
      property: 'contract_expiration',
      width: 90,
      cell: (row) => (
        <OwnTeamTableContractCell row={row} language={language} />
      ),
    },
    {
      key: 'country',
      property: 'country_code',
      width: 85,
      cell: (row) => (
        <OwnTeamTableCountryCell row={row} language={language} />
      ),
    },
    {
      key: 'marketValue',
      property: 'market_value',
      width: 120,
      title: getCurrencyExtension(clubCountryCode),
      cell: (row) => (
        <OwnTeamTableEconomicCell row={row} property={'market_value'} />
      ),
    },
  ];

  if (hasEconomyAccess) {
    ownTeamColumns.push(
      {
        key: 'signingCost',
        property: 'signingCost',
        width: 120,
        title: translate('signingCostLong', language) + ' ' + getCurrencyExtension(clubCountryCode),
        needsEconomyAccess: true,
        cell: (row) => (
          <OwnTeamTableEconomicCell row={row} property={'signingCost'} />
        ),
      },
      {
        key: 'salary',
        property: 'salary',
        width: 105,
        title: translate('salaryCost', language) + ' ' + getCurrencyExtension(clubCountryCode),
        needsEconomyAccess: true,
        cell: (row) => (
          <OwnTeamTableEconomicCell row={row} property={'salary'} />
        ),
      },
      {
        key: 'bonuses',
        property: 'bonuses',
        width: 100,
        title: translate('bonusesExpected', language) + ' ' + getCurrencyExtension(clubCountryCode),
        needsEconomyAccess: true,
        cell: (row) => (
          <OwnTeamTableEconomicCell row={row} property={'bonuses'} />
        ),
      },
    );
  }

  ownTeamColumns.push(
    {
      key: 'locality',
      property: 'locality',
      width: 90,
      cell: (row) => (
        <OwnTeamTableLocalityCell row={row} language={language} />
      ),
    },
    {
      key: 'teamRole',
      property: 'role',
      width: 90 + ownTeamTablePadding,
      cell: (row) => (
        <div className='table-cell' style={{ paddingRight: ownTeamTablePadding }}>
          <OwnTeamTableRoleCell row={row} clubLightColor={clubLightColor} isAcademyTable={isAcademyTable} language={language} />
        </div>
      ),
    },
  );

  return ownTeamColumns;
};
