import { useRecoilState } from 'recoil';
import { globalModalPropsState } from '../atoms/globalModalPropsState';
import { GlobalModalProps, PlayerId, RoleConfig } from '../../types';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { trackEvent } from '../../services/server/analytics/trackEvent';
import { EventPropertiesMap } from '../../services/server/analytics/events';


export const useOpenGlobalModal = () => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const [globalModalProps, setGlobalModalProps] = useRecoilState(globalModalPropsState);


  const openConfirmModal = (
    confirm: () => void,
    confirmTitleKey?: string,
    confirmInfoKey?: string,
    onClose?: () => void,
    confirmKey?: string,
    cancelKey?: string,
    confirmInfo?: string,
    delay?: number,
  ) => {

    const newGlobalModalProps: GlobalModalProps = {
      globalModalTypes: ['ConfirmModal'],
      closeGlobalModal: () => {
        setGlobalModalProps(undefined);
        if (onClose) {
          onClose();
        }
      },
      confirm: () => {
        confirm();
        setGlobalModalProps(undefined);
      },
      confirmTitleKey,
      confirmInfoKey,
      confirmInfo,
      confirmKey,
      cancelKey,
      delay,
    };

    setGlobalModalProps(newGlobalModalProps);
  };


  const openTextModal = (
    textTitle: string,
    text: string,
    eventProperties?: EventPropertiesMap['InfoModalOpened'],
    openedFromRoleInfoModal?: boolean,
    delay?: number,
  ) => {

    const currentGlobalModalProps = globalModalProps ? { ...globalModalProps } : undefined;

    const globalModalTypes = openedFromRoleInfoModal && currentGlobalModalProps
      ? currentGlobalModalProps.globalModalTypes.concat(['TextModal'])
      : ['TextModal'];

    const closeGlobalModal = openedFromRoleInfoModal && currentGlobalModalProps
      ? () => setGlobalModalProps(currentGlobalModalProps)
      : () => setGlobalModalProps(undefined);


    let newGlobalModalProps: GlobalModalProps = {
      globalModalTypes: globalModalTypes,
      closeGlobalModal: closeGlobalModal,
      textTitle,
      text,
      delay,
    };

    if (openedFromRoleInfoModal) {
      newGlobalModalProps = {
        ...currentGlobalModalProps,
        ...newGlobalModalProps,
      };
    }

    if (eventProperties) {
      trackEvent('InfoModalOpened', eventProperties, currentUser, 'user');
    }
    setGlobalModalProps(newGlobalModalProps);
  };


  const openPlayerViewModal = (
    playerId: PlayerId,
    playerName: string,
    onCloseFeedback?: () => void,
    squadId?: string,
    showPlayerNameInTitle?: boolean,
    delay?: number,
  ) => {

    const newGlobalModalProps: GlobalModalProps = {
      globalModalTypes: ['PlayerViewModal'],
      closeGlobalModal: () => setGlobalModalProps(undefined),
      onCloseFeedback,
      playerId,
      playerName,
      squadId,
      showPlayerNameInTitle,
      delay,
    };

    setGlobalModalProps(newGlobalModalProps);
  };


  const openRoleInfoModal = (
    roleConfig: RoleConfig,
    delay?: number,
  ) => {

    const newGlobalModalProps: GlobalModalProps = {
      globalModalTypes: ['RoleInfoModal'],
      closeGlobalModal: () => setGlobalModalProps(undefined),
      roleConfig,
      delay,
    };

    trackEvent('InfoModalOpened', { infoType: 'metric', titleKey: 'role' }, currentUser, 'user');
    setGlobalModalProps(newGlobalModalProps);
  };


  return {
    openConfirmModal,
    openTextModal,
    openPlayerViewModal,
    openRoleInfoModal,
  };
};
