
import { PlayerOverview, RoleConfigMap } from '../../../types';
import { dateStringToLocaleFormat } from '../../../utils/dateUtils';
import { getFormIcon, getPlayingTimeIcon } from '../../../utils/iconUtils';
import { ConfidenceCell, IndexCell, PositionCell } from '../cells';
import { getRoleColumnWidth } from '../utils';


interface PlayerTableColumnCellProps {
  row: PlayerOverview;
  language?: string;
  positionGroup?: string;
}


interface PlayerTableColumn {
  key: string;
  width: number;

  orderByKey?: string;

  textModalInfoKey?: string;
  roleId?: string;

  cell: (props: PlayerTableColumnCellProps) => JSX.Element;
}


export const playerTableInfoColumnsWidth = 410;
export const playerTableInfoColumns: PlayerTableColumn[] = [
  {
    key: 'age',
    width: 60,
    orderByKey: 'age',
    cell: ({ row }: PlayerTableColumnCellProps) => (
      <div className='table-cell'>
        {row.age ?? '-'}
      </div>
    ),
  },
  {
    key: 'contract',
    width: 100,
    cell: ({ row }: PlayerTableColumnCellProps) => (
      <div className='table-cell'>
        {dateStringToLocaleFormat(row.contract_expiration)}
      </div>
    ),
  },
  {
    key: 'position',
    width: 90,
    cell: ({ row, language }: PlayerTableColumnCellProps) => (
      <PositionCell playerData={row} language={language} />
    ),
  },
  {
    key: 'form',
    width: 75,
    textModalInfoKey: 'form',
    cell: ({ row }: PlayerTableColumnCellProps) => (
      <div className='table-cell'>
        {getFormIcon(row.form_status, 18, 'dark', 43)}
      </div>
    ),
  },
  {
    key: 'playingTime',
    width: 85,
    textModalInfoKey: 'playingTime',
    cell: ({ row }: PlayerTableColumnCellProps) => (
      <div className='table-cell'>
        {getPlayingTimeIcon(row.availability_status, 18, 'dark')}
      </div>
    ),
  },
];


export const playerTableStaticDataColumnsWidth = 170;
export const playerTablePadding = 15;
export const getPlayerTableDataColumns = (selectedRoles: string[] | undefined, roleConfigs: RoleConfigMap) => {
  const dataColumns: PlayerTableColumn[] = [
    {
      key: 'skill_index',
      width: 90,
      orderByKey: 'skillIndex',
      cell: ({ row, positionGroup }: PlayerTableColumnCellProps) => (
        <IndexCell playerOverview={row} property='skill_index' positionGroup={positionGroup ?? 'overall'} />
      ),
    },
  ];

  if (selectedRoles) {
    selectedRoles.forEach(roleId => {
      dataColumns.push({
        key: roleId,
        width: getRoleColumnWidth(roleConfigs[roleId].name),
        roleId: roleId,
        orderByKey: roleId,
        cell: ({ row, positionGroup }: PlayerTableColumnCellProps) => (
          <IndexCell playerOverview={row} property={roleId} positionGroup={positionGroup ?? 'overall'} />
        ),
      });
    });
  }

  dataColumns.push({
    key: 'confidence',
    width: 80 + playerTablePadding,
    cell: ({ row, positionGroup }: PlayerTableColumnCellProps) => (
      <div className='table-cell' style={{ paddingRight: playerTablePadding }}>
        <ConfidenceCell playerOverview={row} positionGroup={positionGroup ?? 'overall'} />
      </div>
    ),
  });

  return dataColumns;
};
