import './playerView.css';

import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playerTeamDataSelector } from '../../recoil/selectors/playerTeamDataSelector';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CallMergeIcon from '@mui/icons-material/CallMerge';

import { translate } from '../../../common/language/translations';
import { PlayerFinding } from '../../views/scouting/playerFinding/PlayerFinding';
import { connectPlayerWithoutData, deletePlayerFromPlatform } from '../../services/firestore/players';
import { PlayerOverview } from '../../types';
import { getStrictPlayerId } from '../../utils/playerUtils';
import { TextButton } from '../controls/buttons/TextButton';
import { FindPlayerTable } from '../tables/findPlayerTable/FindPlayerTable';


interface PlayerViewWithoutDataProps {
  setIsConnectingPlayer: (isConnectingPlayer: boolean) => void;
}

export const PlayerViewWithoutData: React.FC<PlayerViewWithoutDataProps> = ({ setIsConnectingPlayer }) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);

  const playerTeamData = useRecoilValue(playerTeamDataSelector);
  const [selectedPlayer, setSelectedPlayer] = useRecoilState(selectedPlayerState);

  const { openConfirmModal } = useOpenGlobalModal();

  const [selectedPlayerToMap, setSelectedPlayerToMap] = useState<PlayerOverview | undefined>(undefined);

  const [isAnyFilterExpanded, setIsAnyFilterExpanded] = useState(false);


  const confirmDeletion = () => {
    if (!selectedPlayer || !userConfig) return;
    deletePlayerFromPlatform(selectedPlayer.id, userConfig.email, userConfig.club, currentUser);
    setSelectedPlayer(undefined);
  };


  const connectPlayer = async () => {
    if (!selectedPlayer || !userConfig || !selectedPlayerToMap || !playerTeamData) return;

    setIsConnectingPlayer(true);

    const currentPlayerId = getStrictPlayerId(selectedPlayer.id);
    const currentTeam = playerTeamData[currentPlayerId] ? playerTeamData[currentPlayerId].currentTeam : undefined;

    const newPlayerId = selectedPlayerToMap.id;
    const newPlayerFullname = selectedPlayerToMap.fullname;
    const newPlayerTeam = playerTeamData[selectedPlayerToMap.id] ? playerTeamData[selectedPlayerToMap.id].currentTeam : undefined;

    await connectPlayerWithoutData(
      currentPlayerId,
      currentTeam,
      newPlayerId,
      newPlayerFullname,
      newPlayerTeam,
      userConfig.email,
      userConfig.club,
      currentUser,
    );

    setSelectedPlayer({
      id: newPlayerId,
      fullname: newPlayerFullname,
      playerOverview: selectedPlayerToMap
    });

    setSelectedPlayerToMap(undefined);

    setIsConnectingPlayer(false);
  };


  return (
    <div className='full-size-container'>

      {isAnyFilterExpanded && <div className='filter-section-empty-background' onClick={() => setIsAnyFilterExpanded(false)} />}

      <div className='player-view-widget-container player-view-without-data-top-section'>
        <div className='player-view-without-data-description'>
          <div>
            {translate('playerWasAddedWithoutData', userConfig?.language)}
          </div>
          <div style={{ marginTop: '1vh' }}>
            {translate('playerWasAddedWithoutDataDescription', userConfig?.language)}
          </div>
        </div>

        <TextButton
          onClick={() => openConfirmModal(confirmDeletion, 'deletePlayer', 'deletePlayerInfo')}
          text={translate('deletePlayer', userConfig?.language)}
          icon={<DeleteForeverIcon style={{ fontSize: 20 }} />}
          buttonType={'dark'}
          style={{ margin: 'auto', height: 32, borderRadius: 4 }}
        />
      </div>

      {!selectedPlayerToMap && (
        <div className='player-view-widget-container player-view-without-data-bottom-section'>
          <PlayerFinding
            isAnyFilterExpanded={isAnyFilterExpanded}
            setIsAnyFilterExpanded={setIsAnyFilterExpanded}
            handlePlayerClick={setSelectedPlayerToMap}
          />
        </div>
      )}

      {selectedPlayerToMap && (
        <div className='player-view-widget-container player-view-without-data-bottom-section'>
          <div className='player-view-without-data-bottom-section-table-container'>
            <FindPlayerTable
              data={[selectedPlayerToMap]}
            />
          </div>

          <TextButton
            onClick={() => openConfirmModal(connectPlayer, 'connectPlayer', 'noRegretInfo')}
            text={translate('connectPlayer', userConfig?.language)}
            icon={<CallMergeIcon style={{ fontSize: 24 }} />}
            style={{ margin: 'auto', marginTop: '30vh', height: 34, borderRadius: 4 }}
          />

          <div className='player-view-without-data-connect-player-link' onClick={() => setSelectedPlayerToMap(undefined)}>
            {translate('searchAgain?', userConfig?.language)}
          </div>

        </div>
      )}

    </div>
  );
};
