import '../../platform.css';
import './planner.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { teamsState } from '../../recoil/atoms/teamsState';

import CloseIcon from '@mui/icons-material/Close';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

import {
  PlannerAction,
  PlannerActionType,
  PlannerConfig,
  PlayerEntry,
  PlayerId,
  PlayerOverviews,
  StringToAnyMap
} from '../../types';

import { TeamView } from '../../components/teamView/TeamView';
import { PlannerTimeline } from '../../components/plots/PlannerTimeline';
import { IconButton } from '../../components/controls/buttons/IconButton';
import { getPlayerOverviews } from '../../services/server/application/playerOverviews';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { playersState } from '../../recoil/atoms/playersState';
import { positionOptionsPlatform } from '../../static/propertyValues';
import { deepCloneObject } from '../../utils/utils';
import { getAge, getAgeCategory, getContractCategory, getPlayerPropertyValue, getStrictPlayerId } from '../../utils/playerUtils';
import { useSetAndTrackSelectedPlayerState } from '../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { PlannerPlayerView } from './PlannerPlayerView';
import { AddPlayerPlannerView } from '../../components/searchPlayers/AddPlayerPlannerView';
import { TeamMenu } from '../../components/teamView/TeamMenu';
import { PlannerStatusView } from './PlannerStatusView';
import { getPlannerConfig, updatePlannerConfig } from '../../services/firestore/planner';
import { Dialog } from '@mui/material';
import { modalPaperProps } from '../../components/modals/globalModals/GlobalModals';
import { PlannerActionModal } from '../../components/modals/PlannerActionModal';
import { getAdjustedDate } from '../../utils/dateUtils';
import { getValidPlannerConfig } from './utils';
import { translate } from '../../../common/language/translations';


export const Planner = () => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const teams = useRecoilValue(teamsState);
  const playerOverviews = useRecoilValue(playerOverviewsState);
  const players = useRecoilValue(playersState);
  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();

  const [originalTeam, setOriginalTeam] = useState<StringToAnyMap | undefined>(undefined);
  const [currentTeam, setCurrentTeam] = useState<StringToAnyMap | undefined>(undefined);

  // mapping of every player in current team to data that might change in the planner (contract, position, role, locality)
  const [originalTeamPlayerMap, setOriginalTeamPlayerMap] = useState<StringToAnyMap | undefined>(undefined);
  const [currentTeamPlayerMap, setCurrentTeamPlayerMap] = useState<StringToAnyMap | undefined>(undefined);
  const [currentTeamSummary, setCurrentTeamSummary] = useState<StringToAnyMap | undefined>(undefined);

  const [additionalPlayerOverviews, setAdditionalPlayerOverviews] = useState<PlayerOverviews | undefined>(undefined);

  const [plannerConfig, setPlannerConfig] = useState<PlannerConfig | undefined>(undefined);
  const [plannerActions, setPlannerActions] = useState<PlannerAction[] | undefined>(undefined);

  const [selectedDate, setSelectedDate] = useState<Date>(getAdjustedDate(new Date()));
  const [selectedPlayerId, setSelectedPlayerId] = useState<PlayerId | undefined>(undefined);
  const [selectedPlayer, setSelectedPlayer] = useState<StringToAnyMap | undefined>(undefined);
  const resetSelectedPlayer = () => { setSelectedPlayerId(undefined); setSelectedPlayer(undefined); };

  const [isPlannerActionModalOpen, setIsPlannerActionModalOpen] = useState<boolean>(false);
  const [plannerActionModalType, setPlannerActionModalType] = useState<PlannerActionType | undefined>(undefined);
  const [selectedAction, setSelectedAction] = useState<PlannerAction | undefined>(undefined);
  const [newPosition, setNewPosition] = useState<string | undefined>(undefined);
  const [contractExpirationDateToExtend, setContractExpirationDateToExtend] = useState<string | undefined>(undefined);

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [isFullScreenExpanding, setIsFullScreenExpanding] = useState<boolean>(false);
  const [isAddPlayerView, setIsAddPlayerView] = useState<boolean>(false);
  const [isRightSectionExpanded, setIsRightSectionExpanded] = useState<boolean>(false);
  const [isLeftSectionExpanded, setIsLeftSectionExpanded] = useState<boolean>(true);

  const [showLeftContent, setShowLeftContent] = useState<boolean>(true);
  const [showRightContent, setShowRightContent] = useState<boolean>(false);
  const [showActions, setShowActions] = useState<boolean>(true);

  const [draggedPlayer, setDraggedPlayer] = useState<StringToAnyMap | undefined>(undefined);
  const [deleteIconIsDraggedOver, setDeleteIconIsDraggedOver] = useState<boolean>(false);
  const [playerWasRecentlyRemoved, setPlayerWasRecentlyRemoved] = useState(false);


  const handleStatusIconClick = () => {
    resetSelectedPlayer();
    setSelectedAction(undefined);
    setIsAddPlayerView(false);
    setIsLeftSectionExpanded(!isLeftSectionExpanded);
  };


  const handleAddPlayerIconClick = () => {
    resetSelectedPlayer();
    setSelectedAction(undefined);
    setIsRightSectionExpanded(false);
    setIsAddPlayerView(!isAddPlayerView);
    if (!isLeftSectionExpanded) setIsLeftSectionExpanded(true);
  };


  const handleFullscreenIconClick = () => {
    setIsFullscreen(!isFullscreen);
    setIsFullScreenExpanding(true);

    if (!isFullscreen) setShowActions(false);

    setTimeout(() => {
      setIsFullScreenExpanding(false);
      if (isFullscreen) setShowActions(true);
    }, 250);
  };


  const handleTeamMenuIconClick = () => {
    resetSelectedPlayer();
    setSelectedAction(undefined);
    setIsRightSectionExpanded(!isRightSectionExpanded);
    setIsAddPlayerView(false);
  };


  const onPlayerClick = (player: StringToAnyMap) => {
    setSelectedPlayerId(player.id);
    setSelectedPlayer(player);
    setSelectedAction(undefined);
    setIsAddPlayerView(false);
    setIsLeftSectionExpanded(true);
  };


  const onActionClick = (action: PlannerAction) => {
    const actionIsSelected = selectedAction !== undefined
      && selectedAction.playerId + selectedAction.date + selectedAction.type === action.playerId + action.date + action.type;

    if (actionIsSelected) {
      setSelectedAction(undefined);
    }
    else {
      setSelectedAction(action);
      setIsAddPlayerView(false);
      setIsLeftSectionExpanded(true);

      if (selectedPlayerId !== action.playerId) {
        setSelectedPlayerId(action.playerId);
        setSelectedPlayer(undefined);
      }
    }
  };


  const openPlannerActionModal = (type?: PlannerActionType, action?: PlannerAction, newPosition?: string, contractExpirationDateToEdit?: string) => {
    if (!type && !action) return;

    let actionType = type ?? action?.type;
    let actionToEdit = action;

    if (actionType === 'contractExpired') {
      actionType = 'contractExtended';
      actionToEdit = undefined;
    }

    setPlannerActionModalType(actionType);
    setSelectedAction(actionToEdit);

    setIsPlannerActionModalOpen(true);
    setNewPosition(newPosition);
    setContractExpirationDateToExtend(contractExpirationDateToEdit);

    if (actionToEdit && (!selectedPlayerId || selectedPlayerId !== actionToEdit.playerId)) {
      setSelectedPlayerId(actionToEdit.playerId);
      setSelectedPlayer(undefined);
    }
  };


  const closePlannerActionModal = () => {
    setIsPlannerActionModalOpen(false);

    setTimeout(() => {
      setSelectedAction(undefined);
      setPlannerActionModalType(undefined);
      setNewPosition(undefined);
      setContractExpirationDateToExtend(undefined);
    }, 100);
  };


  const onPlayerLinkClick = () => {
    if (selectedPlayerId) {
      const numberId = Number(selectedPlayerId);
      const playerOverview = !isNaN(numberId) ? (playerOverviews[numberId] ?? additionalPlayerOverviews?.[numberId] ?? selectedPlayer) : undefined;
      setAndTrackSelectedPlayerState({
        id: selectedPlayerId,
        fullname: playerOverview?.fullname ?? players?.[selectedPlayerId]?.fullname,
        playerOverview: playerOverview,
      });
    }
  };


  const getPlayerPropertyValueInternal = (
    property: string,
    playerId: PlayerId,
  ) => {

    const playerDocument = players[playerId];
    let playerOverview;
    let additionalPlayerOverview;
    if (!isNaN(Number(playerId))) {
      playerOverview = playerOverviews[Number(playerId)];
      additionalPlayerOverview = additionalPlayerOverviews?.[Number(playerId)];
    }

    const value = getPlayerPropertyValue(property, playerDocument, playerOverview, additionalPlayerOverview);
    if (value) {
      return value;
    }

    if (selectedPlayerId === playerId) {
      return selectedPlayer?.[property];
    }
  };


  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => event.preventDefault();
  const handleDragEnter = () => setDeleteIconIsDraggedOver(true);
  const handleDragLeave = () => setDeleteIconIsDraggedOver(false);
  const getBoxShadow = () => deleteIconIsDraggedOver ? '0px 0px 0px 3px #ff2424' : undefined;

  const showDeleteIcon = draggedPlayer !== undefined;

  const handlePlayerDrop = (newPosition: string) => {
    if (draggedPlayer && currentTeamPlayerMap) {
      onPlayerClick(draggedPlayer);
      setDraggedPlayer(undefined);

      const isPlayerAdded = !(draggedPlayer.id in currentTeamPlayerMap);
      openPlannerActionModal(isPlayerAdded ? 'playerAdded' : 'positionChanged', undefined, newPosition);
    }
  };

  const handleRemovePlayerDrop = () => {
    if (draggedPlayer) {
      onPlayerClick(draggedPlayer);
      setDraggedPlayer(undefined);
      openPlannerActionModal('playerRemoved');
    }

    setDeleteIconIsDraggedOver(false);
    setPlayerWasRecentlyRemoved(true);

    setTimeout(() => {
      setPlayerWasRecentlyRemoved(false);
    }, 250);
  };


  const handleUpdatePlannerConfig = async (newConfig: PlannerConfig) => {
    if (!userConfig?.club || !currentUser) return;

    const success = await updatePlannerConfig(newConfig, userConfig?.club, currentUser);
    if (success) {
      setPlannerConfig(newConfig);
    }
  };


  // (1) initialize the original state (team and contracts) and planner config
  useEffect(() => {
    if (!teams || !teams['ownTeam'] || !players || !playerOverviews || !userConfig?.club || !currentUser) return;

    const initializePlanner = async () => {

      const ownTeam = teams['ownTeam'];
      const ownTeamPlayerMap: StringToAnyMap = {};

      positionOptionsPlatform.forEach((position) => {
        const playerList = ownTeam[position] ?? [];
        playerList.forEach((player: PlayerEntry) => {
          ownTeamPlayerMap[player.id] = {
            contract_expiration: getPlayerPropertyValueInternal('contract_expiration', player.id),
            position: position,
            role: players[player.id]?.role,
            locality: players[player.id]?.locality,
          };
        });
      });

      const config = await getPlannerConfig(userConfig?.club, currentUser);
      const validConfig = getValidPlannerConfig(config, ownTeamPlayerMap, ownTeam['formation']);

      handleUpdatePlannerConfig(validConfig);
      setOriginalTeam(ownTeam);
      setOriginalTeamPlayerMap(ownTeamPlayerMap);
    };

    initializePlanner();

  }, [userConfig?.club, currentUser, teams, players, playerOverviews]); // eslint-disable-line react-hooks/exhaustive-deps


  // (2) initialize the planner actions based on the original state (team and contracts) and the planner config
  useEffect(() => {
    if (!originalTeamPlayerMap || !plannerConfig) return;

    // (1) initialize a set of every player id involved in the planner
    const playerIdsInvolved = new Set<PlayerId>(Object.keys(originalTeamPlayerMap).map((playerId) => getStrictPlayerId(playerId)));
    Object.keys(plannerConfig).forEach((playerId) => {
      playerIdsInvolved.add(getStrictPlayerId(playerId));
    });

    // (2) fetch player overviews for playersAdded, if they have data and are not already in the playerOverviews state
    const playerIdsToFetch: number[] = [];
    playerIdsInvolved.forEach((playerId) => {
      const numberId = Number(playerId);
      if (!isNaN(numberId) && !playerOverviews[numberId]) {
        playerIdsToFetch.push(numberId);
      }
    });
    if (playerIdsToFetch.length > 0) {
      getPlayerOverviews(playerIdsToFetch, currentUser).then((additionalPlayerOverviews) => {
        setAdditionalPlayerOverviews(additionalPlayerOverviews ?? {});
      });
    }
    else {
      setAdditionalPlayerOverviews({});
    }

    // (3) iterate through every player involved and create the contractExpired actions - these are not stored in the plannerConfig
    const newPlannerActions: PlannerAction[] = [];
    playerIdsInvolved.forEach((playerId) => {

      const strictPlayerId = getStrictPlayerId(playerId);

      let contractExpiration = originalTeamPlayerMap[strictPlayerId]?.contract_expiration;
      let fullname: string | undefined = getPlayerPropertyValueInternal('fullname', strictPlayerId);

      const playerActions = plannerConfig[strictPlayerId] ?? [];
      playerActions.forEach((action) => {

        if (contractExpiration && action.date > contractExpiration && action.type !== 'playerAdded') {
          console.log('Invalid planner action detected after contract expiration:', action); // eslint-disable-line no-console
        }

        // to compute contract expirations, we only consider relevant actions
        if (action.type === 'contractExtended' || action.type === 'playerRemoved' || action.type === 'playerAdded') {

          // if either of the relevant actions are performed after the original contract expiration, we have detected a contractExpired action
          if (contractExpiration && action.date > contractExpiration) {

            // only addPlayer is a valid action here, as it is the only action that can be performed after a contractExpired action
            newPlannerActions.push({
              type: 'contractExpired',
              date: contractExpiration,
              playerId: strictPlayerId,
              fullname: action.fullname ?? fullname ?? '',
            });

            contractExpiration = undefined;
            if (action.fullname) fullname = action.fullname;
          }

          if (action.type === 'contractExtended') {
            contractExpiration = action.contractExpiration;
          }
          else if (action.type === 'playerAdded') {
            contractExpiration = action.contractExpiration;
            if (action.fullname) fullname = action.fullname;
          }
          else if (action.type === 'playerRemoved') {
            contractExpiration = undefined;
          }
        }

        newPlannerActions.push({
          ...action,
          playerId: strictPlayerId,
          fullname: fullname ?? '',
        });
      });

      // if the contractExpiration is still set, we must add a final contractExpired action
      if (contractExpiration) {
        newPlannerActions.push({
          type: 'contractExpired',
          date: contractExpiration,
          playerId: strictPlayerId,
          fullname: fullname ?? '',
        });
      }
    });

    newPlannerActions.sort((a, b) => a.date.localeCompare(b.date));
    setPlannerActions(newPlannerActions);

  }, [currentUser, originalTeamPlayerMap, plannerConfig]); // eslint-disable-line react-hooks/exhaustive-deps


  // (3) update the current team and current player data based on the planner actions, applying all actions chronologically up to the selected date
  useEffect(() => {
    if (!originalTeam || !plannerActions || !players || !playerOverviews || !additionalPlayerOverviews || !selectedDate) return;

    // (1) initialize the currentTeam and currentTeamPlayerMap
    const newCurrentTeam = deepCloneObject(originalTeam);
    const newCurrentTeamPlayerMap: StringToAnyMap = {};
    positionOptionsPlatform.forEach((position) => {
      const playerList = newCurrentTeam[position] ?? [];
      playerList.forEach((player: PlayerEntry) => {
        newCurrentTeamPlayerMap[player.id] = {
          contract_expiration: getPlayerPropertyValueInternal('contract_expiration', player.id),
          position: position,
          role: players[player.id]?.role,
          locality: players[player.id]?.locality,
        };
      });
    });

    // (2) iterate through the actions chronologically and update the currentTeam and currentTeamPlayerMap with actions before the selectedDate
    plannerActions.forEach((action) => {
      const actionDate = new Date(action.date);
      if (actionDate <= selectedDate) {
        const playerId = action.playerId;

        if (action.type === 'playerAdded') {
          const position = action.position; // will always be set

          if (position) {
            const newPlayerEntry: PlayerEntry = {
              id: playerId,
              fullname: action.fullname,
            };
            newCurrentTeam[position] = newCurrentTeam[position] ?? [];
            newCurrentTeam[position].push(newPlayerEntry);

            newCurrentTeamPlayerMap[playerId] = {
              contract_expiration: action.contractExpiration,
              position: position,
              role: action.role,
              locality: action.locality,
            };
          }
        }

        else if (action.type === 'contractExtended') {
          const newContractExpiration = action.contractExpiration;
          newCurrentTeamPlayerMap[playerId].contract_expiration = newContractExpiration;
        }

        else if (action.type === 'playerRemoved' || action.type === 'contractExpired') {
          positionOptionsPlatform.forEach((position) => {
            const playerList = newCurrentTeam[position] ?? [];
            const newPlayerList = playerList.filter((player: PlayerEntry) => player.id !== playerId);
            newCurrentTeam[position] = newPlayerList;
          });

          delete newCurrentTeamPlayerMap[playerId];
        }

        else if (action.type === 'positionChanged') {
          const position = action.position; // will always be set

          if (position) {

            // we need to iterate through the team and remove the player from the old position
            positionOptionsPlatform.find(position => {
              if (newCurrentTeam[position]) {
                newCurrentTeam[position] = newCurrentTeam[position].filter((player: PlayerEntry) => player.id !== playerId);
              }
            });

            const newPlayerEntry: PlayerEntry = {
              id: playerId,
              fullname: action.fullname,
            };
            newCurrentTeam[position] = newCurrentTeam[position] ?? [];
            newCurrentTeam[position].push(newPlayerEntry);

            newCurrentTeamPlayerMap[playerId].position = position;
          }
        }

        else if (action.type === 'roleChanged') {
          newCurrentTeamPlayerMap[playerId].role = action.role;
        }

        else if (action.type === 'localityChanged') {
          newCurrentTeamPlayerMap[playerId].locality = action.locality;
        }
      }
    });

    // (3) calculate the currentTeamSummary based on the currentTeamPlayerMap
    const newCurrentTeamSummary: StringToAnyMap = {
      'numberOfPlayers': 0,
      'averageAge': undefined,

      // age categories
      'U21': 0,
      'U25': 0,
      'U29': 0,
      '29+': 0,

      // contract categories
      'U1': 0,
      'U2': 0,
      'U3': 0,
      '3+': 0,
    };

    let numberOfPlayers = 0;
    let ageSum = 0;
    let playersWithAge = 0;

    const dateString = selectedDate.toISOString().split('T')[0];
    Object.entries(newCurrentTeamPlayerMap).forEach(([playerId, playerData]) => {
      numberOfPlayers++;

      const birthDate = getPlayerPropertyValueInternal('birth_date', getStrictPlayerId(playerId));
      const age = birthDate ? getAge(birthDate, dateString) : undefined;
      if (age !== undefined) {
        ageSum += age;
        playersWithAge++;
      }

      const ageCategory = getAgeCategory(birthDate, dateString);
      if (ageCategory) {
        newCurrentTeamSummary[ageCategory]++;
      }

      const contractExpiration = playerData.contract_expiration;
      const contractCategory = getContractCategory(contractExpiration, dateString);
      if (contractCategory) {
        newCurrentTeamSummary[contractCategory]++;
      }

      if (playerData.role) {
        newCurrentTeamSummary[playerData.role] = (newCurrentTeamSummary[playerData.role] ?? 0) + 1;
      }

      if (playerData.locality) {
        newCurrentTeamSummary[playerData.locality] = (newCurrentTeamSummary[playerData.locality] ?? 0) + 1;
      }
    });

    newCurrentTeamSummary['numberOfPlayers'] = numberOfPlayers;
    newCurrentTeamSummary['averageAge'] = playersWithAge > 0 ? Math.round(ageSum / playersWithAge * 10) / 10 : undefined;
    newCurrentTeamSummary['maxPlayersInAnyCategory'] = Math.max(
      newCurrentTeamSummary['U21'],
      newCurrentTeamSummary['U25'],
      newCurrentTeamSummary['U29'],
      newCurrentTeamSummary['29+'],
      newCurrentTeamSummary['U1'],
      newCurrentTeamSummary['U2'],
      newCurrentTeamSummary['U3'],
      newCurrentTeamSummary['3+'],
    );

    setCurrentTeam(newCurrentTeam);
    setCurrentTeamPlayerMap(newCurrentTeamPlayerMap);
    setCurrentTeamSummary(newCurrentTeamSummary);

  }, [originalTeam, plannerActions, players, playerOverviews, additionalPlayerOverviews, selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (isLeftSectionExpanded) {
      setIsRightSectionExpanded(false);
      setTimeout(() => {
        setShowLeftContent(true);
      }, 250);
    }
    else {
      setShowLeftContent(false);
    }
  }, [isLeftSectionExpanded]);


  useEffect(() => {
    if (isRightSectionExpanded) {
      setIsLeftSectionExpanded(false);
      setTimeout(() => {
        setShowRightContent(true);
      }, 250);
    }
    else {
      setShowRightContent(false);
    }
  }, [isRightSectionExpanded]);


  return (
    <div className={'platform-view-section' + (isFullscreen ? ' planner-view-fullscreen-container' : ' planner-view-container')}>

      <Dialog
        open={isPlannerActionModalOpen}
        onClose={closePlannerActionModal}
        PaperProps={modalPaperProps}
      >
        {plannerConfig && currentTeamPlayerMap && plannerActionModalType && currentTeam && selectedPlayerId && originalTeamPlayerMap && (
          <PlannerActionModal
            closeModal={closePlannerActionModal}
            type={plannerActionModalType}
            selectedAction={selectedAction}

            selectedPlayerId={selectedPlayerId}
            currentTeamPlayerMap={currentTeamPlayerMap}
            originalTeamPlayerMap={originalTeamPlayerMap}
            fullname={getPlayerPropertyValueInternal('fullname', selectedPlayerId)}

            selectedDate={selectedDate}
            plannerConfig={plannerConfig}
            handleUpdatePlannerConfig={handleUpdatePlannerConfig}

            formation={currentTeam.formation}
            newPosition={newPosition}
            contractExpirationDateToExtend={contractExpirationDateToExtend}
          />
        )}
      </Dialog>

      <div className='planner-view-timeline-container'>
        <PlannerTimeline
          actions={plannerActions ?? []}
          selectedAction={selectedAction}
          onActionClick={onActionClick}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          isFullScreen={isFullscreen}
          isLeftSectionExpanded={isLeftSectionExpanded}
          showActions={showActions}
          language={userConfig?.language ?? 'en'}
        />
      </div>


      {plannerConfig && showLeftContent && (
        <div
          className='planner-view-left-container'
          style={{ top: isFullscreen ? '9vh' : '22vh' }}
        >

          {!isAddPlayerView && selectedPlayerId === undefined && currentTeamSummary && (
            <div className='planner-view-left-section-container fade-in'>
              <PlannerStatusView
                selectedDate={selectedDate}
                currentTeamSummary={currentTeamSummary}
                actions={plannerActions ?? []}
                onActionClick={onActionClick}
              />
            </div>
          )}

          {isAddPlayerView && selectedPlayerId === undefined && (
            <div className='planner-view-left-section-container fade-in'>
              <AddPlayerPlannerView
                onPlayerClick={onPlayerClick}
                draggedPlayer={draggedPlayer}
                setDraggedPlayer={setDraggedPlayer}
              />
            </div>
          )}

          {selectedPlayerId !== undefined && currentTeamPlayerMap && plannerActions && (
            <div className='planner-view-left-section-container fade-in'>
              <PlannerPlayerView
                selectedPlayerId={selectedPlayerId}
                getPlayerPropertyValue={getPlayerPropertyValueInternal}
                onPlayerLinkClick={onPlayerLinkClick}
                currentTeamPlayerMap={currentTeamPlayerMap}
                actions={plannerActions}
                selectedAction={selectedAction}
                onActionClick={onActionClick}
                openPlannerActionModal={openPlannerActionModal}
                selectedDate={selectedDate.toISOString().split('T')[0]}
              />
            </div>
          )}

          {selectedPlayerId !== undefined && (
            <div className='fade-in'>
              <IconButton
                onClick={() => { resetSelectedPlayer(); setSelectedAction(undefined); }}
                icon={<CloseIcon style={{ fontSize: 22 }} />}
                size={24}
                style={{ position: 'absolute', top: 'calc(5vh + 1px)', right: 20, zIndex: 2 }}
                startWhite={true}
                whiteBackgroundOnHover={true}
              />
            </div>
          )}
        </div>
      )}


      <div
        className='planner-view-team-container'
        style={{
          width: isRightSectionExpanded ? 'calc(100% - 270px)' : isLeftSectionExpanded ? '72%' : '100%',
          maxWidth: showLeftContent ? 'calc(100% - 300px)' : '100%',
          right: isRightSectionExpanded ? 270 : isLeftSectionExpanded ? '-1%' : 0,
          top: isFullscreen ? '9vh' : '22vh',
        }}
      >
        {showDeleteIcon && (
          <div
            className='planner-view-delete-player-icon-overlay'
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleRemovePlayerDrop}
          />
        )}

        <div
          className='planner-view-team-icon planner-view-status-icon'
          onClick={() => handleStatusIconClick()}
          title={!isFullscreen ? translate('fullscreen', userConfig?.language) : undefined}
        >
          {!isLeftSectionExpanded && <KeyboardArrowRightIcon style={{ fontSize: 24 }} />}
          {isLeftSectionExpanded && <KeyboardArrowLeftIcon style={{ fontSize: 24 }} />}
        </div>

        <div
          style={{ boxShadow: getBoxShadow(), opacity: playerWasRecentlyRemoved ? 0 : 1 }}
          className={'planner-view-team-icon planner-view-add-player-icon' + (showDeleteIcon ? ' planner-view-delete-player-icon' : '')}
          onClick={() => !showDeleteIcon ? handleAddPlayerIconClick() : null}
          title={!showDeleteIcon && !isAddPlayerView ? translate('addPlayer', userConfig?.language) : undefined}
        >
          {!showDeleteIcon && !isAddPlayerView && <PersonAddAlt1Icon style={{ fontSize: 20, marginLeft: 3, marginTop: -2 }} />}
          {!showDeleteIcon && isAddPlayerView && <CloseIcon style={{ fontSize: 20 }} />}
          {showDeleteIcon && <DeleteForeverIcon style={{ fontSize: 24 }} />}
        </div>

        <div
          className='planner-view-team-icon planner-view-full-screen-icon'
          onClick={() => handleFullscreenIconClick()}
          title={!isFullscreen ? translate('fullscreen', userConfig?.language) : undefined}
        >
          {!isFullscreen && <UnfoldMoreIcon style={{ fontSize: 20 }} />}
          {isFullscreen && <UnfoldLessIcon style={{ fontSize: 20 }} />}
        </div>

        <div
          className='planner-view-team-icon planner-view-team-menu-icon'
          onClick={() => handleTeamMenuIconClick()}
          title={!isRightSectionExpanded ? translate('teamMenu', userConfig?.language) : undefined}
        >
          {!isRightSectionExpanded && <MenuIcon style={{ fontSize: 18 }} />}
          {isRightSectionExpanded && <KeyboardArrowRightIcon style={{ fontSize: 24 }} />}
        </div>

        <TeamView
          teamOrSquadId={'ownTeamPlanner'}
          teamOrSquadData={currentTeam}
          handlePlayerClick={onPlayerClick}
          highlightedPlayerId={selectedPlayerId}
          additionalPlayerOverviews={additionalPlayerOverviews}
          draggedPlayer={draggedPlayer}
          setDraggedPlayer={setDraggedPlayer}
          handlePlayerDrop={handlePlayerDrop}

          isSmallTeamView={!isFullscreen}
          playerSimpleTableWidthTransition={isFullScreenExpanding ? 250 : 0}
          defaultOrderBy={'role'}
          defaultToShortNames={true}
          currentTeamPlayerMap={currentTeamPlayerMap}
          selectedDate={selectedDate}
        />
      </div>

      {showRightContent && (
        <div
          className='planner-view-right-container'
          style={{ top: isFullscreen ? '9vh' : '22vh' }}
        >
          <TeamMenu
            teamOrSquadId={'ownTeamPlanner'}
            defaultOrderBy={'role'}
            defaultToShortNames={true}
            forceShowContent={true}
            resetPlannerConfig={() => handleUpdatePlannerConfig({})}
          />
        </div>
      )}
    </div>
  );
};
