import './playerView.css';

import { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playersState } from '../../recoil/atoms/playersState';
import { teamsState } from '../../recoil/atoms/teamsState';
import { squadsState } from '../../recoil/atoms/squadsState';
import { competitionsState } from '../../recoil/atoms/competitionsState';
import { playerTeamDataSelector } from '../../recoil/selectors/playerTeamDataSelector';
import { playerSquadDataSelector } from '../../recoil/selectors/playerSquadDataSelector';
import { useGetAndAddPlayerOverview } from '../../recoil/hooks/useGetAndAddPlayerOverview';
import { useGetAndAddPlayerCareer } from '../../recoil/hooks/useGetAndAddPlayerCareer';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { trackEvent } from '../../services/server/analytics/trackEvent';

import Dialog from '@mui/material/Dialog';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ConstructionIcon from '@mui/icons-material/Construction';
import AddIcon from '@mui/icons-material/Add';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import TimelineIcon from '@mui/icons-material/Timeline';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import SummarizeIcon from '@mui/icons-material/Summarize';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import EditIcon from '@mui/icons-material/Edit';
// import GraphicEqIcon from '@mui/icons-material/GraphicEq';
// import GraphicEqIcon from '@mui/icons-material/GraphicEq';
// import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
// import TrackChangesIcon from '@mui/icons-material/TrackChanges';
// import LineAxisIcon from '@mui/icons-material/LineAxis';

import {
  getAge,
  getDisplayCompetition,
  getDisplayPositions,
  getPlayerPropertyValue
} from '../../utils/playerUtils';

import { translate } from '../../../common/language/translations';
import { getClubColor } from '../../static/clubConfigs';
import { StringToAnyMap, PlayerOverview, PlayerReport } from '../../types';
import { PlayerViewOverview } from './PlayerViewOverview';
import { PlayerViewCompare } from './PlayerViewCompare';
import { PlayerViewCareer } from './PlayerViewCareer';
import { PlayerViewReports } from './PlayerViewReports';
import { getReportsForPlayer } from '../../services/firestore/reports';
import { PlayerHistoryModal } from '../modals/historyModals/PlayerHistoryModal';
import { getCompletePlayerHistory } from '../../services/firestore/activities';
import { staticTeamKeys } from '../../static/propertyValues';
import { EditPlayerInfoModal } from '../modals/EditPlayerInfoModal';
import { getRoleIcon, getTeamIcon } from '../../utils/iconUtils';
import { PlayerViewWithoutData } from './PlayerViewWithoutData';
import { IconButton } from '../controls/buttons/IconButton';
import { FixedTabs } from '../controls/navigation/FixedTabs';
import { PlayerImage } from '../display/images/PlayerImage';
import { CountryFlag } from '../display/images/CountryFlag';
import { ClubLogo } from '../display/images/ClubLogo';
import { modalPaperProps } from '../modals/globalModals/GlobalModals';
import { dateStringToLocaleFormat } from '../../utils/dateUtils';


export const PlayerView: React.FC = () => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const competitions = useRecoilValue(competitionsState);

  const teams = useRecoilValue(teamsState);
  const squads = useRecoilValue(squadsState);

  const playerTeamData = useRecoilValue(playerTeamDataSelector);
  const playerSquadData = useRecoilValue(playerSquadDataSelector);

  const { openPlayerViewModal } = useOpenGlobalModal();

  const players = useRecoilValue(playersState);
  const [selectedPlayer, setSelectedPlayer] = useRecoilState(selectedPlayerState);
  const [playerOverview, setPlayerOverview] = useState<PlayerOverview | undefined>(selectedPlayer?.playerOverview);
  const playerDocument = selectedPlayer && players ? players[selectedPlayer.id] : undefined;

  const getAndAddPlayerOverview = useGetAndAddPlayerOverview();
  const getAndAddClubIterationMap = useGetAndAddPlayerCareer();

  const [playerViewIsExpanding, setPlayerViewIsExpanding] = useState(true);

  const [reports, setReports] = useState<PlayerReport[]>([]);
  const [initialReport, setInitialReport] = useState<PlayerReport | undefined>(undefined);
  const [initialReportHasPrecedence, setInitialReportHasPrecedence] = useState(selectedPlayer?.initialReportId !== undefined);

  const [playerHistory, setPlayerHistory] = useState<StringToAnyMap[]>([]);

  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);

  const [isEditPlayerInfoModalOpen, setIsEditPlayerInfoModalOpen] = useState(false);
  const [editPlayerInfoProperty, setEditPlayerInfoProperty] = useState<string | undefined>(undefined);

  const [buttonHighlighted, setButtonHighlighted] = useState<string | undefined>(undefined);

  const [isConnectingPlayer, setIsConnectingPlayer] = useState(false);


  const isPlayerWithData = selectedPlayer !== undefined && !isNaN(Number(selectedPlayer.id));
  const isPlayerWithEventData = isPlayerWithData && playerOverview?.event_data_exist;
  const isPlayerWithEventDataAvailable = isPlayerWithEventData && playerOverview?.event_data_available;
  const isPlayerWithBasicStats = isPlayerWithData && playerOverview?.basic_stats_exist;

  const isPlayerWithoutData = selectedPlayer !== undefined && isNaN(Number(selectedPlayer.id));
  const isExistingPlayerWithoutData: boolean = isPlayerWithoutData && selectedPlayer.id in players;
  const isDeletedPlayerWithoutData: boolean = isPlayerWithoutData && !(selectedPlayer.id in players) && selectedPlayer.id !== 'newPlayerWithoutData';
  const isNewPlayerWithoutData: boolean = isPlayerWithoutData && !(selectedPlayer.id in players) && selectedPlayer.id === 'newPlayerWithoutData';

  const [selectedPlayerOverviewIsInitialized, setSelectedPlayerOverviewIsInitialized] = useState(isPlayerWithoutData || playerOverview !== undefined);
  useEffect(() => {
    if (isConnectingPlayer) {
      setSelectedPlayerOverviewIsInitialized(false);
    }
  }, [isConnectingPlayer]);

  const tabOptions = [
    'overview',
    // 'details',
    'compare',
    'career',
    'reports'
  ];

  const tabIcons = [
    <EqualizerIcon key={'equalizer-icon'} style={{ fontSize: 21 }} />,
    // <GraphicEqIcon key={'graphic-eq-icon'} style={{ fontSize: 21 }} />,
    <SsidChartIcon key={'ssid-icon'} style={{ fontSize: 21, marginTop: 0.5 }} />,
    <TimelineIcon key={'timeline-icon'} style={{ fontSize: 21 }} />,
    <SummarizeIcon key={'summarize-icon'} style={{ fontSize: 21 }} />
  ];

  const tabOptionToStatus = {
    'overview': (isPlayerWithoutData || isPlayerWithEventData)
      ? 'enabled'
      : 'blocked',
    'compare': isPlayerWithEventDataAvailable
      ? 'enabled'
      : isPlayerWithEventData
        ? 'locked'
        : 'blocked',
    'career': isPlayerWithBasicStats
      ? 'enabled'
      : 'blocked',
    'reports': 'enabled'
  };

  const initialActiveTab = selectedPlayer?.initialReportId
    ? 3
    : (!selectedPlayerOverviewIsInitialized || isPlayerWithoutData || isPlayerWithEventData || isConnectingPlayer)
      ? 0
      : isPlayerWithBasicStats
        ? 2
        : 3;

  const [activeTab, setActiveTab] = useState(initialActiveTab);


  useEffect(() => {
    // console.log('playerOverview: ', playerOverview);
    // console.log('playerID: ', selectedPlayer && selectedPlayer.id);
    if (selectedPlayer && !isNaN(Number(selectedPlayer.id)) && !playerOverview) {
      getAndAddPlayerOverview(selectedPlayer.id, currentUser).then((newPlayerOverview) => {
        setPlayerOverview(newPlayerOverview);
        setSelectedPlayerOverviewIsInitialized(true);
      });
    }
  }, [currentUser, selectedPlayer]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (selectedPlayer && userConfig) {
      getAndAddClubIterationMap(selectedPlayer.id, currentUser);

      getReportsForPlayer(selectedPlayer.id, userConfig.club, currentUser, selectedPlayer?.initialReportId).then((data) => {
        setReports(data.reports);
        setInitialReport(data.specificReport);
      });
    }
  }, [currentUser, selectedPlayer, userConfig?.club]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (teams && userConfig?.club && selectedPlayer) {
      getCompletePlayerHistory(selectedPlayer.id, reports, userConfig.club).then((history) => {
        setPlayerHistory(history);
      });
    }
  }, [teams, reports, userConfig?.club, selectedPlayer]);


  const onClosePlayerModalFeedback = () => {
    setButtonHighlighted('statusButton');
    setTimeout(() => {
      setButtonHighlighted(undefined);
    }, 800);
  };


  const handleStatusButtonClick = () => {
    if (selectedPlayer && buttonHighlighted !== 'statusButton') {
      openPlayerViewModal(
        selectedPlayer.id,
        selectedPlayer.fullname,
        onClosePlayerModalFeedback,
        selectedPlayer?.squadId,
      );
    }
  };


  const handleEditInfoIconClick = (infoProperty: string) => {
    if (buttonHighlighted !== infoProperty) {
      setIsEditPlayerInfoModalOpen(true);
      setEditPlayerInfoProperty(infoProperty);
    }
  };


  const closeEditPlayerInfoModal = (infoProperty: string | undefined = undefined) => {
    setIsEditPlayerInfoModalOpen(false);

    if (infoProperty === 'role') {
      setTimeout(() => {
        setButtonHighlighted('role');
      }, 100);
      setTimeout(() => {
        setButtonHighlighted(undefined);
      }, 700);
    }
    else {
      setButtonHighlighted(infoProperty);
      setTimeout(() => {
        setButtonHighlighted(undefined);
      }, 800);
    }

    setTimeout(() => {
      setEditPlayerInfoProperty(undefined);
    }, 200);
  };


  const getDisplayName = () => {
    return playerOverview?.fullname ?? selectedPlayer?.fullname ?? '-';
  };


  const getStatusButtonText = () => {
    if (selectedPlayer?.squadId !== undefined) {
      if (playerSquadData && selectedPlayer && selectedPlayer.id in playerSquadData && selectedPlayer.squadId in playerSquadData[selectedPlayer.id]) {
        return squads ? squads[selectedPlayer.squadId]['name'] : '';
      }
      return buttonHighlighted !== 'statusButton' ? translate('addToSquad', userConfig?.language) : '';
    }
    if (playerTeamData && selectedPlayer && selectedPlayer.id in playerTeamData && userConfig) {
      return staticTeamKeys.includes(playerTeamData[selectedPlayer.id].currentTeam)
        ? translate(playerTeamData[selectedPlayer.id].currentTeam, userConfig.language)
        : (teams ? teams[playerTeamData[selectedPlayer.id].currentTeam]['name'] : '');
    }
    return buttonHighlighted !== 'statusButton' ? translate('addToTeam', userConfig?.language) : '';
  };


  const getStatusButtonIcon = () => {
    if (selectedPlayer?.squadId !== undefined) {
      if (playerSquadData && selectedPlayer && selectedPlayer.id in playerSquadData && selectedPlayer.squadId in playerSquadData[selectedPlayer.id]) {
        return <ConstructionIcon style={{ fontSize: 20 }} />;
      }
      return buttonHighlighted !== 'statusButton' ? <AddIcon style={{ fontSize: 20 }} /> : undefined;
    }
    if (playerTeamData && selectedPlayer && selectedPlayer.id in playerTeamData) {
      const currentTeam = playerTeamData[selectedPlayer.id].currentTeam;
      return getTeamIcon(currentTeam, 20);
    }
    return buttonHighlighted !== 'statusButton' ? <AddIcon style={{ fontSize: 20 }} /> : undefined;
  };


  const getPlayerRoleIcon = () => {
    if (players && playerTeamData && selectedPlayer && selectedPlayer.id in playerTeamData) {
      const role = players[selectedPlayer.id]?.role;
      return getRoleIcon(role, 20, buttonHighlighted === 'role' ? clubColor : undefined, undefined, true);
    }
  };


  const handleSetActiveTab = (index: number) => {
    setActiveTab(index);
    if (index !== 3) {
      setInitialReport(undefined);
      setInitialReportHasPrecedence(false);
    }
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setPlayerViewIsExpanding(false);
    }, 150);

    return () => {
      clearTimeout(timer);
    };
  }, []);


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='player-view-section'>

      <Dialog
        open={isEditPlayerInfoModalOpen}
        onClose={() => closeEditPlayerInfoModal()}
        PaperProps={modalPaperProps}
      >
        <EditPlayerInfoModal
          editPlayerInfoProperty={editPlayerInfoProperty}
          closeModal={closeEditPlayerInfoModal}
          playerToEdit={{
            ...selectedPlayer,
            contract_expiration: getPlayerPropertyValue('contract_expiration', playerDocument, playerOverview),
            contract_expiration_in_database: playerOverview?.contract_expiration,
            birth_date: getPlayerPropertyValue('birth_date', playerDocument, playerOverview),
            birth_date_in_database: playerOverview?.birth_date,
            role: getPlayerPropertyValue('role', playerDocument),
            isPlayerWithoutData: isExistingPlayerWithoutData,
          }}
          isAcademyPlayer={
            (playerTeamData
              && selectedPlayer
              && selectedPlayer.id in playerTeamData
              && playerTeamData[selectedPlayer.id].currentTeam === 'academyTeam') ?? false
          }
        />
      </Dialog>

      <Dialog
        open={isHistoryModalOpen}
        onClose={() => setIsHistoryModalOpen(false)}
        PaperProps={modalPaperProps}
      >
        <PlayerHistoryModal
          history={playerHistory}
          closeModal={() => setIsHistoryModalOpen(false)}
        />
      </Dialog>


      <div className='player-view-header'>

        <IconButton
          icon={<ArrowBackIcon style={{ fontSize: 26 }} />}
          onClick={() => setSelectedPlayer(undefined)}
          size={32}
          style={{ position: 'absolute', top: 11, left: 11, zIndex: 5 }}
          startWhite={true}
        />

        <PlayerImage
          src={playerOverview?.image_url}
          isSingleImage={true}
          containerStyle={{ margin: 'auto', width: '13vh', height: 'auto', minWidth: 80, marginLeft: 'calc(2% + 22px)' }}
          imageStyle={{ width: '80%', height: 'auto' }}
        />

        <div className='player-view-info-section'>

          <div className='player-view-name'>
            {getDisplayName()}

            {userConfig && playerTeamData && selectedPlayer && selectedPlayer.id in playerTeamData &&
              (playerTeamData[selectedPlayer.id].currentTeam === 'ownTeam' || playerTeamData[selectedPlayer.id].currentTeam === 'academyTeam') &&
              (
                <IconButton
                  onClick={() => handleEditInfoIconClick('role')}
                  icon={getPlayerRoleIcon()}
                  size={29}
                  style={{ marginLeft: 12 }}
                  startWhite={true}
                  hoverText={translate(playerDocument?.role ? 'editTeamRole' : 'addTeamRole', userConfig.language)}
                />
              )
            }
          </div>

          {userConfig && (
            <div className='player-view-info-row'>

              <div className='player-view-info-column player-view-info-age-column'>
                <div className='player-view-info-label'>
                  {translate('age', userConfig?.language)}
                </div>
                <div className='player-view-info-value' style={{ paddingRight: isExistingPlayerWithoutData ? 20 : undefined }}>
                  {getPlayerPropertyValue('birth_date', playerDocument, playerOverview, undefined, getAge, '-')}
                  {isExistingPlayerWithoutData && (
                    <IconButton
                      onClick={() => handleEditInfoIconClick('birth_date')}
                      icon={<EditIcon style={{ fontSize: 16 }} />}
                      size={24}
                      style={{ position: 'absolute', top: -2, right: -8 }}
                      iconColor={buttonHighlighted === 'birth_date' ? clubColor : undefined}
                    />
                  )}
                </div>
              </div>

              <div className='player-view-info-column'>
                <div className='player-view-info-label'>
                  {translate('position', userConfig?.language)}
                </div>
                <div className='player-view-info-value'>
                  {getDisplayPositions(playerOverview?.primary_positions, userConfig.language) ?? '-'}
                </div>
              </div>

              <div className='player-view-info-column'>
                <div className='player-view-info-label'>
                  {translate('nationality', userConfig?.language)}
                </div>
                <div className='player-view-info-value' style={{ paddingRight: isExistingPlayerWithoutData ? 20 : undefined }}>
                  <CountryFlag
                    countryCode={getPlayerPropertyValue('country_code', playerDocument, playerOverview)}
                    width={16}
                    showCountryName={true}
                    showHyphenIfEmpty={true}
                    containerStyle={{ justifyContent: 'left', gap: 5 }}
                    imageStyle={{ marginBottom: -1 }}
                  />

                  {isExistingPlayerWithoutData && (
                    <IconButton
                      onClick={() => handleEditInfoIconClick('country_code')}
                      icon={<EditIcon style={{ fontSize: 16 }} />}
                      size={24}
                      style={{ position: 'absolute', top: -2, right: -8 }}
                      iconColor={buttonHighlighted === 'country_code' ? clubColor : undefined}
                    />
                  )}
                </div>
              </div>

              <div className='player-view-info-column'>
                <div className='player-view-info-label'>
                  {translate('league', userConfig?.language)}
                </div>
                <div className='player-view-info-value'>{getDisplayCompetition(playerOverview?.club, competitions)}</div>
              </div>

              <div className='player-view-info-column'>
                <div className='player-view-info-label' style={{ display: 'flex', flexDirection: 'row' }}>
                  {translate('team', userConfig?.language)}

                  {playerOverview?.on_loan_from && (
                    <div className='player-view-on-loan-info'>
                      <div style={{ marginTop: -1 }}>
                        {'('}
                      </div>

                      {translate('loanFrom', userConfig.language, true)}

                      <ClubLogo
                        club={playerOverview.on_loan_from}
                        size={12}
                        showClubName={true}
                        containerStyle={{ gap: 2, marginLeft: 3 }}
                      />

                      <div style={{ marginTop: -1 }}>
                        {')'}
                      </div>
                    </div>
                  )}
                </div>
                <div className='player-view-info-value'>
                  <ClubLogo
                    club={playerOverview?.club}
                    size={18}
                    showClubName={true}
                    containerStyle={{ gap: 3 }}
                    showHyphenIfEmpty={true}
                  />
                </div>
              </div>

              <div className='player-view-info-column'>
                <div className='player-view-info-label'>
                  {translate('height', userConfig?.language)}
                </div>
                <div className='player-view-info-value'>{getPlayerPropertyValue('height', playerDocument, playerOverview) ?? '-'}</div>
              </div>

              <div className='player-view-info-column player-view-info-contract-column'>
                <div className='player-view-info-label'>
                  {translate('contract', userConfig?.language)}
                </div>
                <div className='player-view-info-value'>
                  {getPlayerPropertyValue('contract_expiration', playerDocument, playerOverview, undefined, (value) => dateStringToLocaleFormat(value), '-')}
                </div>
              </div>
            </div>
          )}
        </div>

        {(buttonHighlighted === 'contract_expiration' || (playerTeamData && selectedPlayer && selectedPlayer.id in playerTeamData &&
          (playerTeamData[selectedPlayer.id].currentTeam === 'ownTeam' || playerTeamData[selectedPlayer.id].currentTeam === 'academyTeam'))) &&
          (
            <IconButton
              onClick={() => handleEditInfoIconClick('contract_expiration')}
              icon={<EditCalendarIcon style={{ fontSize: 18 }} />}
              size={24}
              style={{ position: 'absolute', top: 'calc(6vh - 10px)', right: 'calc(23% + 10px)', zIndex: 1 }}
              startWhite={true}
              iconColor={buttonHighlighted === 'contract_expiration' ? clubColor : undefined}
              hoverText={translate('editContract', userConfig?.language)}
              hoverStyle={{ fontSize: 11, height: 20, marginLeft: -8, marginTop: -20 }}
            />
          )
        }

        <IconButton
          onClick={() => { trackEvent('PlayerHistoryViewed', {}, currentUser, 'user'); setIsHistoryModalOpen(true); }}
          icon={<LibraryBooksIcon style={{ fontSize: 20 }} />}
          size={30}
          style={{ position: 'absolute', top: '6.5vh', right: '19.3%', zIndex: 1 }}
          startWhite={true}
          hoverText={translate('playerHistory', userConfig?.language)}
          hoverStyle={{ fontSize: 11, height: 20, marginLeft: -50, marginTop: -32 }}
        />

        {!isDeletedPlayerWithoutData && (
          <div>
            <div className='player-view-team-status-text'>
              {translate(selectedPlayer?.squadId !== undefined ? 'squadStatus' : 'teamStatus', userConfig?.language)}
            </div>

            <div className='player-view-team-status-button-container'>
              <div
                className='player-view-team-status-button'
                style={{ backgroundColor: buttonHighlighted === 'statusButton' ? clubColor : undefined }}
                onClick={() => handleStatusButtonClick()}
              >
                <div className='player-view-team-status-button-text'>
                  {getStatusButtonText()}&nbsp;
                </div>

                <div className='player-view-team-status-button-icon'>
                  {getStatusButtonIcon()}
                </div>

                <div
                  className={
                    'player-view-team-status-button-overlay' +
                    (buttonHighlighted === 'statusButton' ? ' player-view-team-status-button-overlay-highlighted' : '')
                  }
                />
              </div>
            </div>
          </div>
        )}

        {isDeletedPlayerWithoutData && (
          <div className='player-view-team-status-text' style={{ top: 'calc(8vh - 10px)' }}>
            {/* todo: add restore button and functionality (?) */}
            {translate('deletedPlayer', userConfig?.language)}
          </div>

        )}

      </div>

      {!isNewPlayerWithoutData && (
        <FixedTabs
          tabOptions={tabOptions}
          tabIcons={tabIcons}
          activeTab={activeTab}
          setActiveTab={handleSetActiveTab}
          parentTab={'playerView'}
          initialActiveTab={initialActiveTab}
          marginTop={'13vh'}
          marginTopLine={'16.9vh'}
          isPlayerView={true}
          playerViewTabOptionToStatus={tabOptionToStatus}
        />
      )}

      <div className='player-view-main-section'>
        {!initialReportHasPrecedence && isNewPlayerWithoutData && (
          <div className='player-view-deleted-player-text'>
            {translate('playerDoesNotExistYet', userConfig?.language)}
          </div>
        )}

        {activeTab === 0 && !initialReportHasPrecedence && isDeletedPlayerWithoutData && !isConnectingPlayer && (
          <div className='player-view-deleted-player-text'>
            {translate('playerIsDeleted', userConfig?.language)}
          </div>
        )}

        {activeTab === 0 && !initialReportHasPrecedence && isExistingPlayerWithoutData && (
          <PlayerViewWithoutData
            setIsConnectingPlayer={setIsConnectingPlayer}
          />
        )}

        {activeTab === 0 && !initialReportHasPrecedence && isPlayerWithEventData && !isConnectingPlayer && (
          <PlayerViewOverview
            playerOverview={playerOverview}
          />
        )}

        {activeTab === 1 && !initialReportHasPrecedence && isPlayerWithEventData && !isConnectingPlayer && (
          <PlayerViewCompare
            playerOverview={playerOverview}
          />
        )}

        {activeTab === 2 && !initialReportHasPrecedence && isPlayerWithBasicStats && !isConnectingPlayer &&
          <PlayerViewCareer />
        }

        {activeTab === 3 && !isConnectingPlayer && (
          <PlayerViewReports
            playerOverview={playerOverview}
            playerEntry={selectedPlayer ? { id: selectedPlayer.id, fullname: selectedPlayer.fullname } : undefined}

            reports={reports}
            setReports={setReports}

            playerViewIsExpanding={playerViewIsExpanding}
            initialReport={initialReport}
            initialReportHasPrecedence={initialReportHasPrecedence}
            setInitialReportHasPrecedence={setInitialReportHasPrecedence}
          />
        )}
      </div>
    </div>
  );
};
