import '../documents.css';

import { useEffect, useReducer, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { allUserConfigsState } from '../../../recoil/atoms/allUserConfigsState';
import { useSetAndTrackSelectedPlayerState } from '../../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';

import clauseReducer from './clauseReducer';
import { initializeState } from './clauseState';

import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreIcon from '@mui/icons-material/Restore';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
  StringToAnyMap,
  StringToStringMap,
  Club,
  ClubMap,
  ClausePayment,
  PlayerClause,
  PlayerOverview,
} from '../../../types';

import {
  getDisplayPaymentAmount,
  getPaymentCurrency,
  getSeasonConditionWithoutCurrentValues,
  getSubClauseWithoutCurrentValuesAndNextClubAndConditionalFees,
  getTotalConditionWithoutCurrentValue
} from '../../../utils/clauseUtils';

import { translate } from '../../../../common/language/translations';
import { SelectPlayerView } from '../../searchPlayers/SelectPlayerView';
import { ClubDropDown } from '../../controls/dropDowns/ClubDropDown';
import { addClause, updateClause } from '../../../services/firestore/clauses';
import { DropDown } from '../../controls/dropDowns/DropDown';
import { clauseCurrencyKeys } from '../../../static/currencies';
import { PaymentModal, getPaymentTitle } from '../../modals/clauseModals/PaymentModal';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { FileUpload } from '../../controls/input/FileUpload';
import { deleteFiles, uploadFiles } from '../../../services/storage/files';
import { Conditions } from './Conditions';
import { SubClauseModal } from '../../modals/clauseModals/SubClauseModal';
import { MaxConditionalFeesModal } from '../../modals/clauseModals/MaxConditionalFeesModal';
import { IconButton } from '../../controls/buttons/IconButton';
import { TextButton } from '../../controls/buttons/TextButton';
import { DateInputField } from '../../controls/input/DateInputField';
import { TextArea } from '../../controls/input/TextArea';
import { ClubLogo } from '../../display/images/ClubLogo';
import { modalPaperProps } from '../../modals/globalModals/GlobalModals';
import { dateStringToLocaleFormat } from '../../../utils/dateUtils';
import { PlayerSimpleTable } from '../../tables/playerSimpleTable/PlayerSimpleTable';
import { getShortenedFileName, openPdfInNewTab } from '../../../utils/fileUtils';


interface ClauseProps {
  isSoldPlayer: boolean;
  existingClauseSelected: PlayerClause | undefined;
  setExistingClauseSelected: (clause: PlayerClause | undefined) => void;

  handleCloseIconClick: () => void;
  addOrUpdateClause: (clause: PlayerClause, isNewClause: boolean, isSoldPlayer: boolean) => void;

  selectedPlayerOverview: PlayerOverview | undefined;
  setSelectedPlayerOverview: (playerOverview: PlayerOverview | undefined) => void;
  allClubs: ClubMap;
  setAllClubs: (clubs: ClubMap) => void;
  userClubId: number;

  isClubsExpanded: boolean;
  setIsClubsExpanded: (isExpanded: boolean) => void;

  isCurrencyExpanded: boolean;
  setIsCurrencyExpanded: (isExpanded: boolean) => void;

  clauseHasUnsavedChanges: boolean;
  setClauseHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}


export const Clause: React.FC<ClauseProps> = ({
  isSoldPlayer,
  existingClauseSelected,
  setExistingClauseSelected,

  handleCloseIconClick,
  addOrUpdateClause,

  selectedPlayerOverview,
  setSelectedPlayerOverview,
  allClubs,
  setAllClubs,
  userClubId,

  isClubsExpanded,
  setIsClubsExpanded,

  isCurrencyExpanded,
  setIsCurrencyExpanded,

  clauseHasUnsavedChanges,
  setClauseHasUnsavedChanges,
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const allUsersWithSameClub = useRecoilValue(allUserConfigsState);
  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();

  const { openConfirmModal, openTextModal } = useOpenGlobalModal();

  const [isLoading, setIsLoading] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const [isPlayerSearchActive, setIsPlayerSearchActive] = useState(false);


  // holds most of the mutable state of a clause, except playerData and files, which need more careful handling
  const [state, dispatch] = useReducer(
    clauseReducer,
    undefined,
    () => initializeState(isSoldPlayer, userClubId, allClubs, existingClauseSelected)
  );

  const [showPlayerIsRequired, setShowPlayerIsRequired] = useState(false);
  const [showClubIsRequired, setShowClubIsRequired] = useState(false);
  const [showTransferDateIsRequired, setShowTransferDateIsRequired] = useState(false);

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [paymentModalHasUnsavedChanges, setPaymentModalHasUnsavedChanges] = useState(false);

  const [isMaxConditionalFeesModalOpen, setIsMaxConditionalFeesModalOpen] = useState(false);

  const [subClauseIndexToEdit, setSubClauseIndexToEdit] = useState<number | undefined>(undefined);
  const [subClauseModalHasUnsavedChanges, setSubClauseModalHasUnsavedChanges] = useState(false);


  const handleSelectPlayer = (player: StringToAnyMap) => {
    setSelectedPlayerOverview(player as PlayerOverview);
    setIsPlayerSearchActive(false);
  };


  const onPlayerClick = (player: StringToAnyMap) => {
    setAndTrackSelectedPlayerState({
      id: player.id,
      fullname: player.fullname,
      playerOverview: player as PlayerOverview,
    });
  };


  const setSelectedCurrency = (currency: string[]) => {
    dispatch({ type: 'SET_CURRENCY', payload: currency[0] });
  };


  const setSelectedClub = (club: Club[]) => {
    const selectedClub = club[0];

    if (!allClubs[selectedClub.id]) {
      setAllClubs({ ...allClubs, [selectedClub.id]: selectedClub });
    }

    dispatch({ type: 'SET_SELECTED_CLUB', payload: selectedClub });
  };


  const handleCloseClubIconClick = () => {
    if (state.sellOnCondition || state.totalConditions.length > 0 || state.seasonConditions.length > 0) {
      openConfirmModal(
        () => dispatch({ type: 'SET_SELECTED_CLUB', payload: undefined }),
        'removeClub',
        'removeClubInfo'
      );
    }
    else {
      dispatch({ type: 'SET_SELECTED_CLUB', payload: undefined });
    }
  };


  const handleUploadFile = (file: File) => {
    const newFileName = file.name;
    if (state.fileNames.includes(newFileName)) {
      openTextModal(
        translate('errorOccurred', userConfig?.language),
        translate('fileAlreadyUploaded', userConfig?.language)
      );
      return;
    }

    dispatch({ type: 'UPLOAD_FILE', payload: file });
  };


  const handleRemoveFile = (fileUrl: string, fileName: string, event?: React.MouseEvent) => {
    if (event) event.stopPropagation();

    const fileExistsInStorage = existingClauseSelected?.fileNames?.includes(fileName) ?? false;
    dispatch({ type: 'REMOVE_FILE', payload: { fileUrl, fileName, fileExistsInStorage } });
  };


  useEffect(() => {
    // revoke the object url to avoid memory leaks
    return () => {
      state.fileUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const setRequiredFields = () => {
    if (!selectedPlayerOverview) setShowPlayerIsRequired(true);
    if (!state.sellingClubId || !state.buyingClubId) setShowClubIsRequired(true);
    if (!state.transferDate) setShowTransferDateIsRequired(true);
  };


  const resetRequiredFields = () => {
    setShowPlayerIsRequired(false);
    setShowClubIsRequired(false);
    setShowTransferDateIsRequired(false);
  };


  const handleAddClause = async () => {
    if (!userConfig || !selectedPlayerOverview || !state.sellingClubId || !state.buyingClubId || !state.transferDate) {
      setRequiredFields();
      return;
    }

    setIsLoading(true);
    resetRequiredFields();

    let clause: PlayerClause = {
      clauseVersion: '1.0.0',
      userEmail: userConfig.email,
      date: new Date().toISOString(),

      isSoldPlayer: isSoldPlayer,
      isDeleted: false,
      isResolved: false,

      playerId: selectedPlayerOverview.id,
      playerName: selectedPlayerOverview.fullname,
      sellingClubId: state.sellingClubId,
      buyingClubId: state.buyingClubId,
      transferDate: state.transferDate,
      comment: state.comment,

      currency: state.currency,
      payments: state.payments,

      sellOnCondition: state.sellOnCondition,
      totalConditions: state.totalConditions.map(totalCondition => getTotalConditionWithoutCurrentValue(totalCondition)),
      seasonConditions: state.seasonConditions.map(seasonCondition => getSeasonConditionWithoutCurrentValues(seasonCondition)),
      maxConditionalFees: state.maxConditionalFees,

      subClauses: state.subClauses ? state.subClauses.map(subClause => getSubClauseWithoutCurrentValuesAndNextClubAndConditionalFees(subClause)) : null,
    };

    // (1) add the clause to firestore and get the clauseId
    const clauseId = await addClause(clause, userConfig.club, currentUser);
    if (!clauseId) {
      setIsLoading(false);
      return;
    }

    if (state.additionalClauseState.uploadedFiles.length > 0) {
      // (2) upload files to storage and get their urls
      const fileUrls = await uploadFiles(state.additionalClauseState.uploadedFiles, 'clauses', clauseId, userConfig.club, currentUser);

      // (3) update the clause with the fileUrls and fileNames
      const newValues = { 'fileUrls': fileUrls, 'fileNames': state.fileNames };
      await updateClause(clauseId, newValues, userConfig.club, currentUser);

      clause = { ...clause, ...newValues };
    }

    setExistingClauseSelected({ ...clause, id: clauseId });
    addOrUpdateClause({ ...clause, id: clauseId }, true, isSoldPlayer);
    setIsLoading(false);
  };


  const handleUpdateClause = async () => {
    if (
      !userConfig
      || !selectedPlayerOverview
      || !existingClauseSelected
      || !existingClauseSelected.id
      || !state.sellingClubId
      || !state.buyingClubId
      || !state.transferDate
    ) {
      setRequiredFields();
      return;
    }

    // only set loading if we need to update storage
    if (state.additionalClauseState.uploadedFiles.length > 0 || state.additionalClauseState.removedFileNames.length > 0) setIsLoading(true);

    resetRequiredFields();

    // (1) add and delete files in storage and get urls for new files
    const fileNameToNewUrl: StringToStringMap = {};
    if (state.additionalClauseState.uploadedFiles.length > 0) {
      const newFileUrls = await uploadFiles(state.additionalClauseState.uploadedFiles, 'clauses', existingClauseSelected.id, userConfig.club, currentUser);
      newFileUrls.forEach((url, index) => {
        fileNameToNewUrl[state.additionalClauseState.uploadedFiles[index].name] = url;
      });
    }
    if (state.additionalClauseState.removedFileNames.length > 0) {
      await deleteFiles(state.additionalClauseState.removedFileNames, 'clauses', existingClauseSelected.id, userConfig.club, currentUser);
    }

    // the url to storage will be the one from fileNameToNewUrl if the file was added now, otherwise it will be the url in fileUrls at the same index
    const newFileUrlsToSet = state.fileNames.map(
      fileName => fileNameToNewUrl[fileName] ?? state.fileUrls[state.fileNames.indexOf(fileName)]
    );

    // (2) update the clause with the new values
    const newValues = {
      playerId: selectedPlayerOverview.id,
      playerName: selectedPlayerOverview.fullname,
      sellingClubId: state.sellingClubId,
      buyingClubId: state.buyingClubId,
      transferDate: state.transferDate,
      comment: state.comment,

      fileUrls: newFileUrlsToSet,
      fileNames: state.fileNames,

      currency: state.currency,
      payments: state.payments,

      sellOnCondition: state.sellOnCondition,
      totalConditions: state.totalConditions.map(totalCondition => getTotalConditionWithoutCurrentValue(totalCondition)),
      seasonConditions: state.seasonConditions.map(seasonCondition => getSeasonConditionWithoutCurrentValues(seasonCondition)),
      maxConditionalFees: state.maxConditionalFees,

      subClauses: state.subClauses ? state.subClauses.map(subClause => getSubClauseWithoutCurrentValuesAndNextClubAndConditionalFees(subClause)) : null,

      incorrectNextClubIds: state.incorrectNextClubIds,
    };

    const success = await updateClause(
      existingClauseSelected.id,
      newValues,
      userConfig.club,
      currentUser,
      { action: 'edited', clauseType: isSoldPlayer ? 'soldPlayer' : 'boughtPlayer' },
    );

    if (success) {
      dispatch({ type: 'SET_IS_EDIT_MODE', payload: false });
      addOrUpdateClause({ ...existingClauseSelected, ...newValues }, false, isSoldPlayer);
    }

    setIsLoading(false);
  };


  const handleSubmitClause = async () => {
    setSubmitButtonDisabled(true);

    if (state.additionalClauseState.isEditMode) {
      await handleUpdateClause();
    }
    else {
      await handleAddClause();
    }

    setSubmitButtonDisabled(false);
  };


  const handleClauseAction = async (isDeleteOrRestore: boolean) => {
    if (!existingClauseSelected || !existingClauseSelected.id || !userConfig) return;

    setSubmitButtonDisabled(true);

    const newValues = isDeleteOrRestore
      ? { isDeleted: !existingClauseSelected.isDeleted, isResolved: false }
      : { isResolved: !existingClauseSelected.isResolved, isDeleted: false };

    const action = isDeleteOrRestore
      ? (existingClauseSelected.isDeleted ? 'restored' : 'deleted')
      : (existingClauseSelected.isResolved ? 'reactivated' : 'resolved');

    const success = await updateClause(
      existingClauseSelected.id,
      newValues,
      userConfig.club,
      currentUser,
      { action: action, clauseType: isSoldPlayer ? 'soldPlayer' : 'boughtPlayer' },
    );

    if (success) {
      addOrUpdateClause({ ...existingClauseSelected, ...newValues }, false, isSoldPlayer);

      if ((isDeleteOrRestore && !existingClauseSelected.isDeleted) || (!isDeleteOrRestore && !existingClauseSelected.isResolved)) {
        handleCloseIconClick();
      }
      else {
        if (isDeleteOrRestore) {
          setExistingClauseSelected({ ...existingClauseSelected, isDeleted: !existingClauseSelected.isDeleted, isResolved: false });
        }
        else {
          setExistingClauseSelected({ ...existingClauseSelected, isResolved: !existingClauseSelected.isResolved, isDeleted: false });
        }
      }
    }

    setSubmitButtonDisabled(false);
  };


  const handleClosePaymentModalClick = () => {
    if (paymentModalHasUnsavedChanges) {
      openConfirmModal(
        () => setIsPaymentModalOpen(false),
        state.payments.length === 0 ? 'discardPayments?' : 'discardChanges?',
        state.payments.length === 0 ? 'paymentsWillNotBeSaved' : 'changesWillNotBeSaved'
      );
    }
    else {
      setIsPaymentModalOpen(false);
    }
  };


  const addOrEditPayments = (payments: ClausePayment[], deletedPayments: ClausePayment[]) => {
    dispatch({ type: 'SET_PAYMENTS', payload: { payments, deletedPayments } });
    setIsPaymentModalOpen(false);
  };


  const openConditionsModalInEditMode = () => {
    setSubClauseIndexToEdit(state.additionalClauseState.activeSubClauseTab);
    dispatch({ type: 'SET_IS_SUB_CLAUSE_MODAL_OPEN', payload: true });
  };


  const closeSubClauseModal = () => {
    dispatch({ type: 'SET_IS_SUB_CLAUSE_MODAL_OPEN', payload: false });
    setTimeout(() => {
      setSubClauseIndexToEdit(undefined);
      setSubClauseModalHasUnsavedChanges(false);
    }, 150);
  };


  const handleCloseSubClauseModal = (forceClose: boolean) => {
    if (subClauseModalHasUnsavedChanges && !forceClose) {
      openConfirmModal(closeSubClauseModal, 'discardChanges?', 'changesWillNotBeSaved');
    }
    else {
      closeSubClauseModal();
    }
  };


  useEffect(() => {
    dispatch({ type: 'SET_TOTAL_TRANSFER_FEE_AND_CONDITIONAL_FEES' });
  }, [state.payments]);


  useEffect(() => {
    if (state.additionalClauseState.paymentHighlightIndex !== undefined) {
      const timer = setTimeout(() => {
        dispatch({ type: 'SET_PAYMENT_HIGHLIGHT_INDEX', payload: undefined });
      }, 700);
      return () => clearTimeout(timer);
    }
  }, [state.additionalClauseState.paymentHighlightIndex]);


  useEffect(() => {
    if (state.additionalClauseState.triggerClauseUpdate) {
      if (!state.additionalClauseState.isEditMode) {
        handleUpdateClause();
      }
      dispatch({ type: 'SET_TRIGGER_CLAUSE_UPDATE', payload: false });
    }
  }, [state.additionalClauseState.triggerClauseUpdate]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    // if clause already has unsaved changes, we don't bother to recheck if the clause ends up back in the same state
    if (!clauseHasUnsavedChanges) {

      // if this is a new clause, the clause has unsaved changes if any data is entered
      if (!existingClauseSelected) {
        if (
          selectedPlayerOverview ||
          state.additionalClauseState.uploadedFiles.length > 0 ||
          state.additionalClauseState.removedFileNames.length > 0 ||
          (state.sellingClubId && state.buyingClubId) ||
          state.transferDate ||
          state.comment ||
          state.currency ||
          state.payments.length > 0 ||
          state.sellOnCondition ||
          state.totalConditions.length > 0 ||
          state.seasonConditions.length > 0 ||
          state.subClauses
        ) {
          setClauseHasUnsavedChanges(true);
        }
      }

      // if clause is being edited, we just assume unsaved changes and don't bother to check if the current state actually has unsaved changes
      else if (state.additionalClauseState.isEditMode) {
        setClauseHasUnsavedChanges(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state,
    existingClauseSelected,
    selectedPlayerOverview,
    clauseHasUnsavedChanges,
  ]);


  useEffect(() => {
    setIsLoading(false);
    setClauseHasUnsavedChanges(false);

    dispatch({
      type: 'INITIALIZE_STATE',
      payload: initializeState(
        isSoldPlayer,
        userClubId,
        allClubs,
        existingClauseSelected,
        state
      )
    });
  }, [existingClauseSelected, isSoldPlayer, userClubId]); // eslint-disable-line react-hooks/exhaustive-deps


  const isActiveClause = !(existingClauseSelected?.isDeleted || existingClauseSelected?.isResolved);


  const buyingClub = state.buyingClubId !== undefined ? state.additionalClauseState.allClubs[state.buyingClubId] : undefined;
  const sellingClub = state.sellingClubId !== undefined ? state.additionalClauseState.allClubs[state.sellingClubId] : undefined;

  const selectedClub = state.isSoldPlayer ? buyingClub : sellingClub;


  const stateAccessorForCurrentConditionData = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;


  const isMaxConditionalFeeReached = stateAccessorForCurrentConditionData.maxConditionalFees !== null &&
    (stateAccessorForCurrentConditionData.conditionalFees ?? 0) >= stateAccessorForCurrentConditionData.maxConditionalFees;


  const stateAccessorForLastConditionData = state.subClauses
    ? state.subClauses[state.subClauses.length - 1]
    : state;


  const shouldNotifyToResolveClause = isActiveClause
    && stateAccessorForLastConditionData.nextClubId !== undefined
    && (!stateAccessorForLastConditionData.sellOnCondition || stateAccessorForLastConditionData.sellOnCondition.isResolved);


  return (
    <div className='document-container'>

      <Dialog
        open={isPaymentModalOpen}
        onClose={() => handleClosePaymentModalClick()}
        PaperProps={modalPaperProps}
      >
        <PaymentModal
          state={state}
          closeModal={() => handleClosePaymentModalClick()}
          payments={state.payments}
          addOrEditPayments={addOrEditPayments}
          isEdit={state.payments.length > 0}
          hasUnsavedChanges={paymentModalHasUnsavedChanges}
          setHasUnsavedChanges={setPaymentModalHasUnsavedChanges}
        />
      </Dialog>

      <Dialog
        open={state.additionalClauseState.isSubClauseModalOpen}
        onClose={() => handleCloseSubClauseModal(false)}
        PaperProps={modalPaperProps}
      >
        <SubClauseModal
          closeModal={handleCloseSubClauseModal}
          subClauseIndexToEdit={subClauseIndexToEdit}
          setAllClubs={setAllClubs}
          hasUnsavedChanges={subClauseModalHasUnsavedChanges}
          setHasUnsavedChanges={setSubClauseModalHasUnsavedChanges}
          state={state}
          dispatch={dispatch}
        />
      </Dialog>

      <Dialog
        open={isMaxConditionalFeesModalOpen}
        onClose={() => setIsMaxConditionalFeesModalOpen(false)}
        PaperProps={modalPaperProps}
      >
        <MaxConditionalFeesModal
          closeModal={() => setIsMaxConditionalFeesModalOpen(false)}
          state={state}
          dispatch={dispatch}
        />
      </Dialog>

      <div>
        <IconButton
          onClick={handleCloseIconClick}
          icon={<CloseIcon style={{ fontSize: 24 }} />}
          size={26}
          style={{ position: 'absolute', top: 'calc(2vh - 6px)', right: 'calc(3% - 4px)', zIndex: 5 }}
          startWhite={true}
          whiteBackgroundOnHover={true}
        />

        <div className='clause-title-row'>
          <div className='clause-column clause-left-column '>
            {existingClauseSelected === undefined && (
              <div className='clause-title'>
                {translate(isSoldPlayer ? 'newClauseForSoldPlayer' : 'newClauseForBoughtPlayer', userConfig?.language)}
              </div>
            )}

            {existingClauseSelected !== undefined && (
              <div className='clause-title-info'>
                {allUsersWithSameClub[existingClauseSelected.userEmail] ? allUsersWithSameClub[existingClauseSelected.userEmail].name + ', ' : ''}
                {existingClauseSelected.date && dateStringToLocaleFormat(existingClauseSelected.date.split('T')[0])}
              </div>
            )}
          </div>

          <div className='clause-vertical-divider' style={{ backgroundColor: '#ffffff00' }} />

          <div className='clause-column clause-right-column'>
            <div className='clause-title-transfer-fee'>
              {translate('totalTransferFee', userConfig?.language)}:

              <div
                style={{
                  marginLeft: 12,
                  color: state.additionalClauseState.paymentHighlightIndex !== undefined
                    ? state.additionalClauseState.paymentHighlightIndex >= 0
                      ? '#9df78b'
                      : '#ff8888'
                    : undefined
                }}>
                {getDisplayEconomicValue(state.totalTransferFee, true)}
              </div>

              {!state.currency && (!existingClauseSelected || state.additionalClauseState.isEditMode) && (
                <div className='clause-currency-drop-down'>
                  <DropDown
                    id={'clause-currency-select'}
                    dropDownOptions={clauseCurrencyKeys}
                    selectedOptions={state.currency ? [state.currency] : []}
                    setSelectedOptions={setSelectedCurrency}
                    isDropDownExpanded={isCurrencyExpanded}
                    setIsDropDownExpanded={setIsCurrencyExpanded}
                    defaultDropDownText={'currency'}
                    defaultDropDownTextColor={'#000000'}
                    marginBetweenOptions={0}
                    forceSelection={true}
                    height={24}
                  />
                </div>
              )}

              {state.currency && (
                <div className='clause-currency-drop-down'>
                  {state.currency}

                  <IconButton
                    onClick={() => dispatch({ type: 'SET_CURRENCY', payload: null })}
                    icon={<CloseIcon style={{ fontSize: 18 }} />}
                    size={24}
                    style={{ marginLeft: 10, visibility: (!existingClauseSelected || state.additionalClauseState.isEditMode) ? undefined : 'hidden' }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='document-horizontal-divider document-horizontal-top-divider' />

        <div className='document-horizontal-divider document-horizontal-bottom-divider' />

        <div className='document-submit-section document-submit-section-wide'>
          {(!existingClauseSelected || state.additionalClauseState.isEditMode) && !isLoading && (
            <TextButton
              onClick={() => !submitButtonDisabled ? handleSubmitClause() : null}
              text={translate(state.additionalClauseState.isEditMode ? 'confirmEdits' : 'submitClause', userConfig?.language)}
              icon={state.additionalClauseState.isEditMode ? <CheckIcon style={{ fontSize: 21 }} /> : <ArrowRightAltIcon style={{ fontSize: 21 }} />}
              buttonType={'transparent'}
            />
          )}

          {existingClauseSelected && !state.additionalClauseState.isEditMode && !isLoading && (
            <TextButton
              onClick={() => !submitButtonDisabled ? dispatch({ type: 'SET_IS_EDIT_MODE', payload: true }) : null}
              text={translate('editClause', userConfig?.language)}
              icon={<EditNoteIcon style={{ fontSize: 21 }} />}
              buttonType={'transparent'}
              style={{ width: 185 }}
            />
          )}

          {existingClauseSelected && !state.additionalClauseState.isEditMode && !isLoading && (
            <TextButton
              onClick={() => !submitButtonDisabled ? handleClauseAction(false) : null}
              text={translate(existingClauseSelected.isResolved ? 'reactivateClause' : 'resolveClause', userConfig?.language)}
              icon={
                existingClauseSelected.isResolved
                  ? <UpdateIcon style={{ fontSize: 21 }} />
                  : shouldNotifyToResolveClause
                    ? <NotificationsIcon style={{ fontSize: 16 }} />
                    : <TaskAltIcon style={{ fontSize: 20 }} />
              }
              buttonType={'transparent'}
              style={{ width: 185 }}
              iconIsNotificationIcon={shouldNotifyToResolveClause}
            />
          )}

          {existingClauseSelected && !state.additionalClauseState.isEditMode && !isLoading && (
            <TextButton
              onClick={() => !submitButtonDisabled ? handleClauseAction(true) : null}
              text={translate(existingClauseSelected.isDeleted ? 'restoreClause' : 'deleteClause', userConfig?.language)}
              icon={
                existingClauseSelected.isDeleted
                  ? <RestoreIcon style={{ fontSize: 21 }} />
                  : <DeleteOutlineIcon style={{ fontSize: 21 }} />
              }
              buttonType={'transparent'}
              style={{ width: 185 }}
            />
          )}

          {isLoading && (
            <div style={{ margin: 'auto', marginBottom: -2 }}>
              <div className='loading-spinner loading-spinner-small' />
            </div>
          )}
        </div>

      </div>

      <div className='document-content-section clause-content-section'>

        <div className='clause-column clause-left-column'>

          <div className='clause-left-column-section'>
            {selectedPlayerOverview && (
              <div className='clause-player-table fade-in' key={existingClauseSelected?.id ?? 'newClause'}>
                <PlayerSimpleTable
                  tableData={[selectedPlayerOverview]}
                  onPlayerClick={onPlayerClick}
                  tableType={'playerButton'}
                />
              </div>
            )}

            {selectedPlayerOverview && (!existingClauseSelected || state.additionalClauseState.isEditMode) && (
              <IconButton
                onClick={() => setSelectedPlayerOverview(undefined)}
                icon={<CloseIcon style={{ fontSize: 18 }} />}
                size={24}
                style={{ position: 'absolute', top: 24, left: -5 }}
              />
            )}

            {(!existingClauseSelected || state.additionalClauseState.isEditMode) && !selectedPlayerOverview && (
              <SelectPlayerView
                onPlayerClick={handleSelectPlayer}
                titleKey={'selectPlayer'}
                maxTableHeight={'63vh'}
                setIsPlayerSearchActive={setIsPlayerSearchActive}
                marginTop={'5px'}
                marginBottom={'8px'}
              />
            )}

            {(!existingClauseSelected || state.additionalClauseState.isEditMode) && !selectedPlayerOverview && showPlayerIsRequired && (
              <div className='document-required-star clause-content-required-star-player'>
                *
              </div>
            )}
          </div>

          {!isPlayerSearchActive && (
            <div className='clause-left-column-section clause-club-section'>
              <div className='document-input-title'>
                {translate(isSoldPlayer ? 'soldTo' : 'boughtFrom', userConfig?.language)}
              </div>

              {!selectedClub && (
                <div className='clause-club-drop-down'>
                  <ClubDropDown
                    id={'clause-clubs-drop-down'}
                    isDropDownExpanded={isClubsExpanded}
                    setIsDropDownExpanded={setIsClubsExpanded}
                    selectedClubs={[]}
                    setSelectedClubs={setSelectedClub}
                    defaultDropDownText={'selectClub'}
                    defaultDropDownTextColor={'#00000080'}
                    marginBetweenOptions={3}
                    maxHeight={'60vh'}
                    singleSelect={true}
                  />
                </div>
              )}

              {!selectedClub && showClubIsRequired && (
                <div className='document-required-star clause-content-required-star-club'>
                  *
                </div>
              )}

              {selectedClub && (
                <div className='clause-input-display-field'>

                  {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
                    <IconButton
                      onClick={handleCloseClubIconClick}
                      icon={<CloseIcon style={{ fontSize: 18 }} />}
                      size={24}
                      style={{ marginLeft: -30, marginRight: 6 }}
                    />
                  )}

                  <ClubLogo
                    club={selectedClub}
                    size={16}
                    showClubName={true}
                  />
                </div>
              )}
            </div>
          )}

          {!isPlayerSearchActive && (
            <div className='clause-left-column-section clause-date-section'>
              <div className='document-input-title'>
                {translate('transferDate', userConfig?.language)}
              </div>

              {!state.transferDate && (
                <DateInputField
                  value={null}
                  placeholder={translate('selectDate', userConfig?.language)}
                  onChange={(date: Date | null) => dispatch({ type: 'SET_TRANSFER_DATE', payload: date })}
                  minDate='today-10y'
                  maxDate='today+10y'
                />
              )}

              {!state.transferDate && showTransferDateIsRequired && (
                <div className='document-required-star clause-content-required-star-date'>
                  *
                </div>
              )}

              {state.transferDate && (
                <div className='clause-input-display-field'>
                  {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
                    <IconButton
                      onClick={() => dispatch({ type: 'SET_TRANSFER_DATE', payload: null })}
                      icon={<CloseIcon style={{ fontSize: 18 }} />}
                      size={24}
                      style={{ marginLeft: -30, marginRight: 6, marginTop: 1 }}
                    />
                  )}

                  {dateStringToLocaleFormat(state.transferDate)}
                </div>
              )}
            </div>
          )}

          {!isPlayerSearchActive && !isClubsExpanded && (
            <div className='clause-text-area-container'>
              <TextArea
                value={state.comment}
                placeholder={translate(
                  existingClauseSelected && !state.additionalClauseState.isEditMode ? 'noCommentsProvided' : 'provideComments',
                  userConfig?.language
                )}
                placeholderFontSize={13}
                onChange={(event) => dispatch({ type: 'SET_COMMENT', payload: event.target.value })}
                disabled={(existingClauseSelected !== undefined && !state.additionalClauseState.isEditMode) || isPlayerSearchActive}
              />
            </div>
          )}

          {!isPlayerSearchActive && !isClubsExpanded && (
            <div className='document-file-container clause-file-container fast-fade-in'>

              <div className='document-input-title' style={{ marginTop: state.fileUrls.length > 1 ? -6 : 6, width: 'fit-content', position: 'relative' }}>

                <div style={{ paddingLeft: state.fileNames.length > 0 ? 10 : undefined }}>
                  {translate('attachments', userConfig?.language)}
                </div>

                {state.fileUrls.length > 0 && (
                  <div style={{ position: 'absolute', left: -16, top: 0.5, fontSize: 12, color: '#ffffffaa' }}>
                    ({state.fileUrls.length})
                  </div>
                )}

                {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
                  <div style={{ position: 'absolute', right: -32, top: -3, zIndex: 1 }}>
                    <FileUpload handleFileUpload={handleUploadFile} language={userConfig?.language ?? 'en'} buttonType='iconButton' />
                  </div>
                )}
              </div>

              <div className='document-file-container-existing-files-container'>
                {state.fileUrls.length === 0 && existingClauseSelected && !state.additionalClauseState.isEditMode && (
                  <div style={{ fontSize: 12, color: '#ffffffaa', marginTop: 30 }}>
                    {translate('noAttachments', userConfig?.language)}
                  </div>
                )}
                <div className='document-file-container-existing-files clause-file-container-existing-files'>
                  {state.fileUrls.map((fileUrl, index) => {
                    if (state.fileNames.length <= index) return null;

                    const fileName = state.fileNames[index];
                    return (
                      <div
                        key={index}
                        className='document-file-container-existing-file clause-file-container-existing-file'
                        onClick={() => openPdfInNewTab(fileUrl, fileName, 'clauses', currentUser)}
                      >
                        {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
                          <IconButton
                            onClick={(event?: React.MouseEvent) => handleRemoveFile(fileUrl, fileName, event)}
                            icon={<CloseIcon style={{ fontSize: 18 }} />}
                            size={24}
                            style={{ position: 'absolute', marginLeft: -36 }}
                            hoverStyle={{ right: 32 }}
                          />
                        )}
                        <PictureAsPdfIcon style={{ fontSize: 18, marginRight: 6 }} />
                        {getShortenedFileName(fileName, 22)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

        </div>

        <div className='clause-vertical-divider' />

        <div className='clause-column clause-right-column'>

          <div className='clause-right-column-section clause-right-column-top-section'>
            <div className='clause-right-column-section-title-row'>
              <div className='clause-right-column-section-title'>
                {translate('payments', userConfig?.language)}

                {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
                  <IconButton
                    onClick={() => setIsPaymentModalOpen(true)}
                    icon={state.payments.length > 0 ? <EditIcon style={{ fontSize: 18, marginTop: 1 }} /> : <AddCircleOutlineIcon style={{ fontSize: 20 }} />}
                    size={24}
                    style={{ position: 'absolute', right: -32, top: -4 }}
                    hoverText={translate(state.payments.length === 0 ? 'addPayments' : 'addOrEditPayments', userConfig?.language)}
                  />
                )}
              </div>
            </div>

            <div className='clause-payment-row'>
              {state.payments.map((payment, index) => {
                return (
                  <div
                    className='clause-existing-payment'
                    style={{ borderColor: state.additionalClauseState.paymentHighlightIndex === index ? '#9df78b' : '#ffffff10' }}
                    key={index}
                  >
                    <div className='clause-existing-payment-title text-no-wrap' style={{ fontSize: 11, color: '#ffffffdd' }}>
                      {getPaymentTitle(payment, userConfig?.language ?? 'en')}
                    </div>
                    <div className='clause-existing-payment-value' style={{ fontSize: 11, marginTop: 5, color: '#ffffffcc' }}>
                      {dateStringToLocaleFormat(payment.date)}
                    </div>
                    <div
                      className='clause-existing-payment-value'
                      style={{ display: 'flex', alignItems: 'baseline', fontSize: 12, marginLeft: 2, marginTop: 9, color: '#ffffff', textWrap: 'nowrap' }}
                    >
                      {getDisplayPaymentAmount(payment, state)}
                      <div style={{ marginLeft: 3, fontSize: 11, color: '#ffffffdd' }}>
                        {getPaymentCurrency(payment, state)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className='clause-horizontal-divider' />

          <div className='clause-right-column-section clause-right-column-bottom-section'>
            <div className='clause-right-column-section-title-row' style={{ marginTop: 8 }}>
              <div className='clause-right-column-section-title'>
                {translate('conditions', userConfig?.language)}

                {(!existingClauseSelected || state.additionalClauseState.isEditMode) && (
                  <IconButton
                    onClick={() => dispatch({ type: 'SET_IS_CONDITION_MODAL_OPEN', payload: true })}
                    icon={<AddCircleOutlineIcon style={{ fontSize: 20 }} />}
                    size={24}
                    style={{ position: 'absolute', right: -32, top: -4 }}
                    hoverText={translate('addCondition', userConfig?.language)}
                  />
                )}
              </div>

              {(state.subClauses && state.subClauses.length > 0) && (
                <div className='clause-right-column-section-club-navigation-container'>
                  {[buyingClub, ...state.subClauses.map(subClause => state.additionalClauseState.allClubs[subClause.buyingClubId])].map((club, index) => {
                    if (!club) return null;

                    const subClauseIndex = index === 0 ? undefined : index - 1;

                    return (
                      <div
                        key={index}
                        className={
                          'clause-right-column-section-club-logo-container' +
                          (state.additionalClauseState.activeSubClauseTab === subClauseIndex ? ' clause-right-column-section-club-logo-container-active' : '')
                        }
                        onClick={() => dispatch({ type: 'SET_ACTIVE_SUB_CLAUSE_TAB', payload: subClauseIndex })}
                      >
                        <ClubLogo
                          club={club}
                          size={16}
                        />
                      </div>
                    );
                  })}

                  {state.additionalClauseState.activeSubClauseTab !== undefined && (
                    <IconButton
                      onClick={openConditionsModalInEditMode}
                      icon={<MoreVertIcon style={{ fontSize: 16 }} />}
                      size={20}
                    />
                  )}

                  {state.subClauses && state.additionalClauseState.activeSubClauseTab !== undefined && (
                    <div style={{ marginLeft: 3, fontSize: 12, color: '#ffffffee' }}>
                      {state.subClauses[state.additionalClauseState.activeSubClauseTab].totalSellOnPercentage}%
                    </div>
                  )}

                  {state.subClauses
                    && state.additionalClauseState.activeSubClauseTab !== undefined
                    && state.subClauses[state.additionalClauseState.activeSubClauseTab].currency
                    && (
                      <div style={{ marginLeft: 5, fontSize: 12, color: '#ffffffee' }}>
                        {state.subClauses[state.additionalClauseState.activeSubClauseTab].currency}
                      </div>
                    )
                  }
                </div>
              )}

              <div className='clause-right-column-section-club-max-amount-container'>
                <div
                  className='clause-right-column-section-club-max-amount-link'
                  onClick={() => setIsMaxConditionalFeesModalOpen(true)}
                  title={translate(
                    stateAccessorForCurrentConditionData.maxConditionalFees === null ? 'provideMaxAmountForConditions' : 'editMaxAmountForConditions',
                    userConfig?.language
                  )}
                >
                  {stateAccessorForCurrentConditionData.maxConditionalFees === null && translate('maxAmount', userConfig?.language) + '?'}

                  {isMaxConditionalFeeReached && (
                    <div
                      className='clause-existing-condition-status-icon clause-existing-condition-resolved-icon'
                      style={{ pointerEvents: 'none' }}
                    >
                      <TaskAltIcon style={{ fontSize: 12, marginTop: -3 }} />
                    </div>
                  )}

                  {stateAccessorForCurrentConditionData.maxConditionalFees !== null && (
                    getDisplayEconomicValue(stateAccessorForCurrentConditionData.conditionalFees ?? 0, true)
                    + ' / ' +
                    getDisplayEconomicValue(stateAccessorForCurrentConditionData.maxConditionalFees, true)
                  )}
                </div>
              </div>
            </div>

            <Conditions
              existingClauseSelected={existingClauseSelected}
              state={state}
              dispatch={dispatch}
            />
          </div>

        </div>

      </div>

    </div >
  );
};
