
import {
  goalkeeperSeasonTableMetricGroups,
  metricToDisplayInfo,
  outfieldSeasonTableMetricGroups,
  skillcornerStats
} from '../../../static/playerMetrics';

import { PlayerClubIteration, RoleConfigMap, StringToAnyMap, StringToStringArrayMap } from '../../../types';
import { IterationMetricCell } from '../cells';
import { getMetricColumnWidth } from '../utils';


interface SeasonTableColumn {
  key: string;
  width: number;
  roleId?: string;
  metricName?: string;
  cell: (row: PlayerClubIteration) => JSX.Element;
}


export const getSeasonTableColumnGroups = (
  positionGroup: string,
  positionGroupToRoleIds: StringToStringArrayMap,
  roleConfigs: RoleConfigMap,
  hasSkillcorner: boolean,
  seasonStatsToggles: StringToAnyMap,
  language: string,
  isColorBlind: boolean,
): { [key: string]: SeasonTableColumn[] } => {

  const columnGroups: { [key: string]: SeasonTableColumn[] } = {};

  const roleRatings = positionGroupToRoleIds[positionGroup] ?? [];
  const ratingMetricGroup = { 'ratings': ['skill_rating', ...roleRatings] };
  const normalMetricGroups = positionGroup === 'GK' ? goalkeeperSeasonTableMetricGroups : outfieldSeasonTableMetricGroups;
  const skillCornerMetricGroup = hasSkillcorner && positionGroup !== 'GK' ? { 'skillcorner': skillcornerStats } : {};
  const metricGroups = { ...ratingMetricGroup, ...normalMetricGroups, ...skillCornerMetricGroup };

  Object.entries(metricGroups).forEach(([metricGroupKey, metrics]: [string, string[]]) => {

    columnGroups[metricGroupKey] = metrics.map(metric => {

      const roleConfig = roleConfigs[metric];

      const metricName = roleConfig !== undefined
        ? roleConfigs[metric].name
        : metricToDisplayInfo[metric]?.name[language];

      return {
        key: metric,
        width: getMetricColumnWidth(metricName),
        roleId: roleConfig ? metric : undefined,
        metricName: metricName,
        cell: (row: PlayerClubIteration) => (
          <div className='table-cell'>
            <IterationMetricCell
              playerClubIteration={row}
              property={metric}
              metricGroupKey={metricGroupKey}
              metricGroupIsToggled={seasonStatsToggles[metricGroupKey]}
              isColorBlind={isColorBlind}
              roleConfig={roleConfig}
              positionGroup={positionGroup}
            />
          </div>
        ),
      };
    });

  });

  return columnGroups;
};
