import '../../platform.css';
import './searchPlayers.css';

import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { playersState } from '../../recoil/atoms/playersState';
import { playerOverviewsState } from '../../recoil/atoms/playerOverviewsState';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { playerTeamDataSelector } from '../../recoil/selectors/playerTeamDataSelector';
import { nameSearchHistorySelector } from '../../recoil/selectors/scoutingConfigSelectors';

import { StringToAnyMap, PlayerEntry, PlayerId, PlayerOverviewsQueryOptions } from '../../types';
import { translate } from '../../../common/language/translations';
import { InputField } from '../controls/input/InputField';
import { debounce } from '../../utils/utils';
import { searchPlayerOverviews, SearchPlayerOverviewsResponse } from '../../services/server/application/playerOverviews';
import { teamsState } from '../../recoil/atoms/teamsState';
import { positionOptionsPlatform } from '../../static/propertyValues';
import { playerSimpleTablePageSize } from '../../views/scouting/Scouting';
import { PlayerSimpleTable } from '../tables/playerSimpleTable/PlayerSimpleTable';


interface AddPlayerPlannerViewProps {
  onPlayerClick: (player: StringToAnyMap) => void;
  draggedPlayer: StringToAnyMap | undefined;
  setDraggedPlayer: (player: StringToAnyMap | undefined) => void;
}


export const AddPlayerPlannerView: React.FC<AddPlayerPlannerViewProps> = ({
  onPlayerClick,
  draggedPlayer,
  setDraggedPlayer,
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);
  const nameSearchHistory = useRecoilValue(nameSearchHistorySelector);

  const players = useRecoilValue(playersState);
  const playerOverviews = useRecoilValue(playerOverviewsState);
  const playerTeamData = useRecoilValue(playerTeamDataSelector);
  const teams = useRecoilValue(teamsState);

  const [academyPlayers, setAcademyPlayers] = useState<StringToAnyMap[]>([]);
  const [existingPlayersWithoutData, setExistingPlayersWithoutData] = useState<StringToAnyMap[]>([]);

  const [searchString, setSearchString] = useState('');
  const [debouncedSearchString, setDebouncedSearchString] = useState<string>('');
  const clearDebounceRef = useRef<() => void>(() => null);

  const [activeSearchString, setActiveSearchString] = useState('');
  const [shouldShowSearchResult, setShouldShowSearchResult] = useState(false);
  const [isSearchWithoutResult, setIsSearchWithoutResult] = useState(false);

  const [tableData, setTableData] = useState<StringToAnyMap[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalHits, setTotalHits] = useState(0);

  const [isAddPlayerWithoutData, setIsAddPlayerWithoutData] = useState(false);


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (!isAddPlayerWithoutData) {
        handleSearchForPlayers(true, true);
      }
    }
  };


  const setEmptyTableData = () => {
    setTableData([]);
    setCurrentPage(0);
    setTotalHits(0);
  };


  const handleSearchForPlayers = async (isUserInitiated: boolean, isNewSearch: boolean) => {

    setIsLoading(true);

    if (isUserInitiated) {
      clearDebounceRef.current();
    }

    if (searchString !== '') {

      const nextPageToQuery = isNewSearch ? 1 : currentPage + 1;
      const querySearchString = searchString;
      const queryOptions: PlayerOverviewsQueryOptions = {
        name: querySearchString.toLowerCase(),
        page: nextPageToQuery,
        pageSize: playerSimpleTablePageSize,
      };

      const result: SearchPlayerOverviewsResponse | undefined = await searchPlayerOverviews(
        queryOptions,
        currentUser,
        nameSearchHistory,
        userConfig?.email,
        userConfig?.club
      );
      setActiveSearchString(querySearchString);

      if (!result) {
        // todo: handle error?
        setEmptyTableData();
      }
      else if (result.total_hits === 0 || (isNewSearch && result.players.length === 0)) {
        setIsSearchWithoutResult(true);
        setEmptyTableData();
      }
      else {
        setIsSearchWithoutResult(false);
        setTableData(isNewSearch ? result.players : [...tableData, ...result.players]);
        setCurrentPage(result.current_page);
        setTotalHits(result.total_hits);
      }
    }

    setIsLoading(false);
  };


  const handleMessageLinkClick = () => {
    setSearchString('');
    if (isAddPlayerWithoutData) {
      setIsAddPlayerWithoutData(false);
    }
    else {
      setIsAddPlayerWithoutData(true);
    }
  };


  useEffect(() => {
    if (players && playerTeamData) {
      const existingPlayersWithoutData: StringToAnyMap[] = [];

      Object.keys(players).forEach((playerId: PlayerId) => {
        if (isNaN(Number(playerId))) {
          const player = { ...players[playerId] };

          if (playerTeamData && playerId in playerTeamData) {
            player['currentTeam'] = playerTeamData[playerId].currentTeam;
          }

          existingPlayersWithoutData.push(player);
        }
      });

      setExistingPlayersWithoutData(existingPlayersWithoutData);
    }
  }, [players, playerTeamData]);


  useEffect(() => {
    const newTableData: StringToAnyMap[] = [];

    tableData.forEach((player: StringToAnyMap) => {
      const playerData: StringToAnyMap = {
        id: player['id'],
        image_url: player['image_url'],
        fullname: player['fullname'],
        club: player.club,
        country_code: player['country_code'],
        primary_positions: player['primary_positions'],
      };
      if (playerTeamData && player['id'] in playerTeamData) {
        playerData['currentTeam'] = playerTeamData[player['id']].currentTeam;
      }
      newTableData.push(playerData);
    });

    setTableData(newTableData);
  }, [playerTeamData]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (teams && players) {
      const academy: StringToAnyMap[] = [];

      positionOptionsPlatform.forEach(positionKey => {

        if (teams['academyTeam'] && positionKey in teams['academyTeam']) {
          teams['academyTeam'][positionKey].forEach((player: PlayerEntry) => {
            let playerCopy: StringToAnyMap = { currentTeam: 'academyTeam', ...player };

            if (player['id'] in playerOverviews) {
              playerCopy['image_url'] = playerOverviews[Number(player['id'])]['image_url'];
            }
            if (player['id'] in players) {
              playerCopy = { ...playerCopy, ...players[player['id']] };
            }

            academy.push(playerCopy);
          });
        }

      });

      setAcademyPlayers(academy);
    }
  }, [teams, players, playerOverviews]);


  const resetSearch = () => {
    setSearchString('');
    setDebouncedSearchString('');
    setShouldShowSearchResult(false);
    setEmptyTableData();
    setIsSearchWithoutResult(false);
  };


  useEffect(() => {
    if (searchString.length === 0) {
      resetSearch();
    }

    else if (isAddPlayerWithoutData) {
      setShouldShowSearchResult(true);
      setIsSearchWithoutResult(false);
      setTableData(
        [{
          id: 'newPlayerWithoutData',
          fullname: searchString,
        }]
      );
    }

    if (!isAddPlayerWithoutData) {
      const [debounceFilter, clearDebounce] = debounce(() => {
        if (searchString !== debouncedSearchString) {
          setDebouncedSearchString(searchString);
        }
      }, 600);

      debounceFilter();

      clearDebounceRef.current = clearDebounce;

      return () => {
        clearDebounce();
      };
    }
  }, [searchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (debouncedSearchString !== '') {
      handleSearchForPlayers(false, true);
    }
  }, [debouncedSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (searchString !== '' && searchString === activeSearchString) {
      setShouldShowSearchResult(true);
    }
  }, [searchString, activeSearchString]);


  return (
    <div className='add-player-view-container add-player-planner-view-container'>

      <div className='add-player-view-message-link' style={{ marginTop: 4, fontSize: 12 }} onClick={handleMessageLinkClick}>
        {translate(isAddPlayerWithoutData ? 'searchForPlayer?' : 'addPlayerWithoutData?', userConfig?.language)}
      </div>
      
      {
      !isAddPlayerWithoutData && 
        <div className='add-player-view-title' style={{ marginTop: '5vh' }}>
          {translate('addPlayer', userConfig?.language)}
        </div>
      }
      {
        !isAddPlayerWithoutData &&
        <div
          style={{ marginTop: '9vh', width: 230 }}
          className='add-player-view-input-field-container'
        >
          <InputField
            value={searchString}
            placeholder={translate(isAddPlayerWithoutData ? 'providePlayerName' : 'searchForName', userConfig?.language)}
            onChange={onChangeSearchField}
            onKeyDown={onKeyDownSearchField}
            resetValue={resetSearch}
            showNameSearchHistory={!isAddPlayerWithoutData}
            onNameSearchHistoryClick={(name: string) => { setSearchString(name); setDebouncedSearchString(name); }}
            height={26}
            maxHeight={'54vh'}
          />
        </div>
      }


      {shouldShowSearchResult && !isAddPlayerWithoutData && (
        <div className='add-player-view-result-section'>

          {isSearchWithoutResult && (
            <div className='add-player-view-no-result fade-in'>
              {translate('noResult', userConfig?.language)}
            </div>
          )}

          {!isSearchWithoutResult && (
            <div className='add-player-view-table'>
              <PlayerSimpleTable
                tableData={tableData}
                tableType={isAddPlayerWithoutData ? 'addPlayerViewTeamIcon' : 'addPlayerView'}
                maxHeight={'51vh'}
                positionKey={'addPlayerTable'}
                draggedPlayer={draggedPlayer}
                setDraggedPlayer={setDraggedPlayer}

                isSearchResultTable={true}
                isLoading={isLoading}
                handleSearchForPlayers={handleSearchForPlayers}
                currentPage={currentPage}
                totalHits={totalHits}
                onPlayerClick={onPlayerClick}
              />
            </div>
          )}

        </div>
      )}


      {academyPlayers.length > 0
        && !isAddPlayerWithoutData
        && !shouldShowSearchResult
        && searchString.length === 0
        && (
          <div
            className='add-player-view-existing-players'
            style={{ marginTop: '18vh', height: '49vh' }}>
            <div className='add-player-view-existing-players-title'>
              {translate('academyPlayers', userConfig?.language)}
            </div>

            <div className='add-player-view-players-without-data-table'>
              <PlayerSimpleTable
                tableData={academyPlayers}
                tableType={'addPlayerViewRoleIcon'}
                maxHeight={'44vh'}
                positionKey={'addPlayerTableExistingPlayers'}
                draggedPlayer={draggedPlayer}
                setDraggedPlayer={setDraggedPlayer}
                onPlayerClick={onPlayerClick}
              />
            </div>
          </div>
        )}


      {isAddPlayerWithoutData && existingPlayersWithoutData.length > 0 && (
        <div
          className='add-player-view-existing-players'
          style={{ marginTop: '6vh', height: '40vh' }}>
          <div className='add-player-view-existing-players-title'>
            {translate('existingPlayersWithoutData', userConfig?.language)}
          </div>

          <div className='add-player-view-players-without-data-table'>
            <PlayerSimpleTable
              tableData={existingPlayersWithoutData}
              onPlayerClick={onPlayerClick}
              tableType={'addPlayerViewTeamIcon'}
              maxHeight={'37vh'}
              positionKey={'addPlayerTableExistingPlayers'}
              draggedPlayer={draggedPlayer}
              setDraggedPlayer={setDraggedPlayer}
            />
          </div>
        </div>
      )}

    </div>
  );
};
