
import { translate } from '../../../../common/language/translations';
import { StringToAnyMap } from '../../../types';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RocketIcon from '@mui/icons-material/Rocket';


interface TeamTableColumn {
  property: string;
  width: number;
  title?: string;
  header: JSX.Element;
  cell: (row: StringToAnyMap) => JSX.Element;
}


export const teamTableColumnsWidth = 500;
export const teamTablePadding = 15;

export const getTeamTableColumns = (isAdvancedTeamDataToggled: boolean, language?: string): TeamTableColumn[] => {

  if (isAdvancedTeamDataToggled) {
    return [
      {
        property: 'matchesPlayedAdvanced',
        width: teamTableColumnsWidth / 7,
        title: translate('matchesPlayed', language),
        header: <div>{translate('matchesPlayedShort', language)}</div>,
        cell: (row) => (
          <div className='table-cell' style={{ color: '#ffffffda' }}>
            {row.matchesPlayed}
          </div>
        ),
      },
      {
        property: 'xG',
        width: teamTableColumnsWidth / 7,
        title: translate('xGPer90', language),
        header: <div>xG +</div>,
        cell: (row) => (
          <div className='table-cell' style={{ color: '#ffffffda' }}>
            {row.xG}
          </div>
        ),
      },
      {
        property: 'xGC',
        width: teamTableColumnsWidth / 7,
        title: translate('xGCPer90', language),
        header: <div>xG -</div>,
        cell: (row) => (
          <div className='table-cell' style={{ color: '#ffffffda' }}>
            {row.xGC}
          </div>
        ),
      },
      {
        property: 'xGDifference',
        width: teamTableColumnsWidth / 7,
        title: translate('xGDifferencePer90', language),
        header: <div>xG +/-</div>,
        cell: (row) => (
          <div className='table-cell' style={{ color: '#ffffffda' }}>
            {row.xGDifference}
          </div>
        ),
      },
      {
        property: 'averageAgePerMinutePlayed',
        width: teamTableColumnsWidth / 7,
        title: translate('averageAgePerMinutePlayed', language),
        header: <CalendarMonthIcon style={{ fontSize: 20, marginTop: -2 }} />,
        cell: (row) => (
          <div className='table-cell' style={{ color: '#ffffffda' }}>
            {row.averageAgePerMinutePlayed}
          </div>
        ),
      },
      {
        property: 'u21MinutesPerMinute',
        width: teamTableColumnsWidth / 7,
        title: translate('u21MinutesPerMinute', language),
        header: <RocketIcon style={{ fontSize: 20, marginTop: -2, marginLeft: -2 }} />,
        cell: (row) => (
          <div className='table-cell' style={{ color: '#ffffffda' }}>
            {row.u21MinutesPerMinute}
          </div>
        ),
      },
      {
        property: 'xPts',
        width: (teamTableColumnsWidth / 7) + teamTablePadding,
        title: translate('xP', language),
        header: <div>xP</div>,
        cell: (row) => (
          <div className='table-cell' style={{ color: '#ffffff', fontWeight: 600, paddingRight: teamTablePadding }}>
            {row.xPts}
          </div>
        ),
      },
    ];
  }

  return [
    {
      property: 'matchesPlayed',
      width: teamTableColumnsWidth / 8,
      title: translate('matchesPlayed', language),
      header: <div>{translate('matchesPlayedShort', language)}</div>,
      cell: (row) => (
        <div className='table-cell' style={{ color: '#ffffffda' }}>
          {row.matchesPlayed}
        </div>
      ),
    },
    {
      property: 'matchesWon',
      width: teamTableColumnsWidth / 8,
      title: translate('matchesWon', language),
      header: <div>{translate('matchesWonShort', language)}</div>,
      cell: (row) => (
        <div className='table-cell' style={{ color: '#ffffffda' }}>
          {row.matchesWon}
        </div>
      ),
    },
    {
      property: 'matchesDrawn',
      width: teamTableColumnsWidth / 8,
      title: translate('matchesDrawn', language),
      header: <div>{translate('matchesDrawnShort', language)}</div>,
      cell: (row) => (
        <div className='table-cell' style={{ color: '#ffffffda' }}>
          {row.matchesDrawn}
        </div>
      ),
    },
    {
      property: 'matchesLost',
      width: teamTableColumnsWidth / 8,
      title: translate('matchesLost', language),
      header: <div>{translate('matchesLostShort', language)}</div>,
      cell: (row) => (
        <div className='table-cell' style={{ color: '#ffffffda' }}>
          {row.matchesLost}
        </div>
      ),
    },
    {
      property: 'goalsScored',
      width: teamTableColumnsWidth / 8,
      title: translate('goalsScored', language),
      header: <div>+</div>,
      cell: (row) => (
        <div className='table-cell' style={{ color: '#ffffffda' }}>
          {row.goalsScored}
        </div>
      ),
    },
    {
      property: 'goalsConceded',
      width: teamTableColumnsWidth / 8,
      title: translate('goalsConceded', language),
      header: <div>-</div>,
      cell: (row) => (
        <div className='table-cell' style={{ color: '#ffffffda' }}>
          {row.goalsConceded}
        </div>
      ),
    },
    {
      property: 'goalDifference',
      width: teamTableColumnsWidth / 8,
      title: translate('goalDifference', language),
      header: <div>+/-</div>,
      cell: (row) => (
        <div className='table-cell' style={{ color: '#ffffffda' }}>
          {row.goalDifference}
        </div>
      ),
    },
    {
      property: 'points',
      width: (teamTableColumnsWidth / 8) + teamTablePadding,
      title: translate('points', language),
      header: <div>{translate('pointsShort', language)}</div>,
      cell: (row) => (
        <div className='table-cell' style={{ color: '#ffffff', fontWeight: 600, paddingRight: teamTablePadding }}>
          {row.points}
        </div>
      ),
    },
  ];
};
