import '../modals.css';

import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { teamsState } from '../../../recoil/atoms/teamsState';
import { squadsState } from '../../../recoil/atoms/squadsState';
import { selectedPlayerState } from '../../../recoil/atoms/selectedPlayerState';
import { navigationPropsState } from '../../../recoil/atoms/navigationPropsState';
import { useSetAndTrackNavigationPropsState } from '../../../recoil/hooks/useSetAndTrackNavigationPropsState';
import { playerTeamDataSelector } from '../../../recoil/selectors/playerTeamDataSelector';
import { playerSquadDataSelector } from '../../../recoil/selectors/playerSquadDataSelector';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import {
  addPlayerToSquad,
  addPlayerToTeam,
  removePlayerFromTeamOrSquad,
} from '../../../services/firestore/teamsOrSquads';

import { translate } from '../../../../common/language/translations';
import { DropDown } from '../../controls/dropDowns/DropDown';
import { formationToPositionOptions } from '../../../static/propertyValues';
import { addPlayerWithoutData, deleteOwnTeamAndAcademyPlayerData, deletePlayerDocument, setPlayerProperties } from '../../../services/firestore/players';
import { NavigationProps, PlayerEntry, PlayerId } from '../../../types';
import { userHasFullAccess } from '../../../utils/userUtils';
import { IconButton } from '../../controls/buttons/IconButton';
import { TextButton } from '../../controls/buttons/TextButton';


interface PlayerViewModalProps {
  closeModal: (feedback?: boolean) => void;
  playerId: PlayerId;
  playerName: string;
  squadId: string | undefined;
  showPlayerNameInTitle?: boolean;
}

export const PlayerViewModal: React.FC<PlayerViewModalProps> = ({
  closeModal,
  playerId,
  playerName,
  squadId,
  showPlayerNameInTitle,
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);

  const navigationProps = useRecoilValue(navigationPropsState);
  const setAndTrackNavigationProps = useSetAndTrackNavigationPropsState();
  const { openConfirmModal } = useOpenGlobalModal();

  const setSelectedPlayer = useSetRecoilState(selectedPlayerState);

  const teams = useRecoilValue(teamsState);
  const squads = useRecoilValue(squadsState);
  const playerTeamData = useRecoilValue(playerTeamDataSelector) ?? {};
  const playerSquadData = useRecoilValue(playerSquadDataSelector) ?? {};

  const currentTeam = playerTeamData && playerId in playerTeamData
    ? playerTeamData[playerId].currentTeam
    : undefined;

  const currentTeamPosition = !squadId && playerTeamData && playerId in playerTeamData
    ? playerTeamData[playerId].currentPosition
    : undefined;

  const currentSquadPosition = squadId && playerSquadData && playerId in playerSquadData
    ? playerSquadData[playerId][squadId]
    : undefined;

  const [selectedPosition, setSelectedPosition] = useState<string[]>(
    currentSquadPosition
      ? [currentSquadPosition]
      : currentTeamPosition ? [currentTeamPosition] : []);

  const [selectedTeam, setSelectedTeam] = useState<string[]>(currentTeam ? [currentTeam] : []);

  const [isPositionDropDownExpanded, setIsPositionDropDownExpanded] = useState(false);
  const [isTeamDropDownExpanded, setIsTeamDropDownExpanded] = useState(false);


  const [formationOfSelectedTeamOrSquad, setFormationOfSelectedTeamOrSquad] = useState<string | undefined>(
    squadId
      ? (squads && squadId in squads ? squads[squadId]['formation'] : undefined)
      : (teams && currentTeam && currentTeam in teams ? teams[currentTeam]['formation'] : undefined)
  );

  const [positionOptions, setPositionOptions] = useState<string[]>(
    formationOfSelectedTeamOrSquad ? formationToPositionOptions[formationOfSelectedTeamOrSquad] : []
  );


  useEffect(() => {
    if (!squadId && teams && selectedTeam.length > 0) {
      setFormationOfSelectedTeamOrSquad(
        teams && selectedTeam[0] && selectedTeam[0] in teams ? teams[selectedTeam[0]]['formation'] : undefined
      );
    }
  }, [selectedTeam, squadId, teams]);


  useEffect(() => {
    if (formationOfSelectedTeamOrSquad) {
      setPositionOptions(formationToPositionOptions[formationOfSelectedTeamOrSquad]);

      if (selectedPosition.length > 0 && !formationToPositionOptions[formationOfSelectedTeamOrSquad].includes(selectedPosition[0])) {
        setSelectedPosition([]);
      }
    }
  }, [formationOfSelectedTeamOrSquad]); // eslint-disable-line react-hooks/exhaustive-deps


  const removeDropDownExpansions = () => {
    setIsPositionDropDownExpanded(false);
    setIsTeamDropDownExpanded(false);
  };


  const closeModalAndResetSlections = (feedback: boolean, clauseModalTypeToOpen: string | undefined) => {
    closeModal(feedback);

    // There's a small delay before the modal is closed
    setTimeout(() => {
      setSelectedTeam([]);
      setSelectedPosition([]);

      if (clauseModalTypeToOpen) {
        handleOpenAddClauseModal(clauseModalTypeToOpen);
      }
    }, 250);
  };


  // will not be called if already on the economy tab
  const confirmAddClause = (wasAdded: boolean) => {
    const newNavigationProps: NavigationProps = {
      activeTab: 'economy',
      activeSubTab: wasAdded ? 1 : 0,
      selectedPlayerId: playerId,
    };
    setAndTrackNavigationProps(newNavigationProps);
    setSelectedPlayer(undefined);
  };


  const handleOpenAddClauseModal = (handleOpenAddClauseModal: string) => {
    if (navigationProps && navigationProps.activeTab === 'economy') return;

    const isBoughtPlayer = handleOpenAddClauseModal === 'boughtPlayer';

    openConfirmModal(
      () => confirmAddClause(isBoughtPlayer),
      'addClause?',
      isBoughtPlayer ? 'addClauseForBoughtPlayerInfo' : 'addClauseForSoldPlayerInfo',
      undefined,
      undefined,
      undefined,
      undefined,
      50,
    );
  };


  const handleRemovePlayerFromOwnTeamOrAcademyTeam = (playerId: PlayerId) => {
    if (!userConfig) return;

    // if this is a player in the database, we delete the player document
    if (!isNaN(Number(playerId))) {
      deletePlayerDocument(playerId, userConfig.club);
    }

    // if this is a player without data, we must only delete the ownTeam and academyTeam player data
    else {
      deleteOwnTeamAndAcademyPlayerData(playerId, userConfig.club, currentUser);
    }
  };


  const handleAddOrMovePlayer = async () => {
    if (!userConfig || !userConfig || !teams || !squads) return;

    let clauseModalTypeToOpen: string | undefined = undefined;

    let newPlayerId: PlayerId | undefined = playerId;
    if (playerId === 'newPlayerWithoutData') {
      newPlayerId = await addPlayerWithoutData(playerName, userConfig.club, currentUser);
    }

    if (newPlayerId === undefined) return;

    const playerData: PlayerEntry = {
      id: newPlayerId,
      fullname: playerName,
    };

    if (!squadId) {
      const newTeamId = selectedTeam[0];

      const currentTeamData = playerId in playerTeamData
        ? {
          teamId: playerTeamData[playerId].currentTeam,
          teamData: teams[playerTeamData[playerId].currentTeam],
          currentPosition: playerTeamData[playerId].currentPosition,
        }
        : undefined;

      addPlayerToTeam(
        playerData,
        selectedPosition[0],
        undefined,
        newTeamId,
        teams[newTeamId],
        currentTeamData,
        userConfig.email,
        userConfig.club,
        'modal',
        currentUser,
      );

      if (newTeamId !== currentTeam) {

        // if player is added to ownTeam, modal to add clause be opened unless player is moved from academyTeam
        if (newTeamId === 'ownTeam' && currentTeam !== 'academyTeam' && !isNaN(Number(newPlayerId))) {
          clauseModalTypeToOpen = 'boughtPlayer';
        }

        // if player is removed from ownTeam, modal to add clause for the removed player will be opened
        if (currentTeam === 'ownTeam' && !isNaN(Number(newPlayerId))) {
          clauseModalTypeToOpen = 'soldPlayer';
        }

        // if player is moved from ownTeam or academyTeam, we need to reset parts or all of the player table data
        if (currentTeam === 'ownTeam' || currentTeam === 'academyTeam') {

          // if player is moved between ownTeam and academyTeam, we only reset the role
          if (newTeamId === 'academyTeam' || newTeamId === 'ownTeam') {
            setPlayerProperties(newPlayerId, { role: null }, userConfig.club, currentUser);
          }

          // if the player moves to another team, we reset the player table data
          else {
            handleRemovePlayerFromOwnTeamOrAcademyTeam(newPlayerId);
          }
        }
      }
    }

    else {
      addPlayerToSquad(
        playerData,
        selectedPosition[0],
        undefined,
        squadId,
        squads[squadId],
        userConfig.email,
        userConfig.club,
        'modal',
        currentUser,
      );
    }

    closeModalAndResetSlections(true, clauseModalTypeToOpen);
  };


  const handleRemovePlayer = () => {
    if (!userConfig) return;

    let clauseModalTypeToOpen: string | undefined = undefined;

    if (squadId) {
      removePlayerFromTeamOrSquad(playerId, squadId, true, userConfig.email, userConfig.club, currentUser, 'modal');
    }
    else {
      removePlayerFromTeamOrSquad(playerId, currentTeam, false, userConfig.email, userConfig.club, currentUser, 'modal');

      if (currentTeam === 'ownTeam' || currentTeam === 'academyTeam') {
        handleRemovePlayerFromOwnTeamOrAcademyTeam(playerId);

        if (currentTeam === 'ownTeam' && !isNaN(Number(playerId))) {
          clauseModalTypeToOpen = 'soldPlayer';
        }
      }
    }

    setSelectedPlayer(undefined);
    closeModalAndResetSlections(false, clauseModalTypeToOpen);
  };


  const hasFullAccess = userHasFullAccess(userConfig);
  const teamsToDisable = hasFullAccess ? [] : ['ownTeam'];
  const showTeamDropDown = !squadId && !teamsToDisable.includes(currentTeam);

  // There are 4 different cases: addPlayer/editStatus and squadContext/teamContext (all combinations)
  const isEditStatusView = squadId
    ? playerId in playerSquadData && squadId in playerSquadData[playerId]
    : currentTeam !== undefined;


  const modalTitle = showPlayerNameInTitle
    ? playerName
    : isEditStatusView
      ? translate(squadId ? 'editSquadStatus' : 'editTeamStatus', userConfig?.language)
      : squadId && squads && squadId in squads
        ? translate('addPlayerTo', userConfig?.language) + ' ' + squads[squadId].name
        : translate('addToTeam', userConfig?.language);

  const buttonText = isEditStatusView
    ? translate(squadId ? 'changePosition' : 'movePlayer', userConfig?.language)
    : translate(squadId ? 'addToSquad' : 'addPlayer', userConfig?.language);


  const isAddOrMoveButtonDisabled = squadId
    ? selectedPosition.length === 0 || selectedPosition[0] === currentSquadPosition
    : selectedTeam.length === 0 || selectedPosition.length === 0 || (selectedTeam[0] === currentTeam && selectedPosition[0] === currentTeamPosition);

  const isRemoveButtonDisabled = !squadId && currentTeam === 'ownTeam' && !hasFullAccess;


  return (
    <div className='modal-root-container player-view-modal-root'>

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={() => closeModal()}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      {(isPositionDropDownExpanded || isTeamDropDownExpanded) &&
        <div className='modal-empty-background' onClick={() => removeDropDownExpansions()} />
      }

      <div className='modal-root-title'>
        {modalTitle}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      <div className='modal-content-container'>
        <div
          className='modal-content-section'
          style={{ minHeight: 200 }}
        >
          <div className='player-view-modal-select-row' style={{ marginTop: 20 }}>
            {showTeamDropDown && (
              <div className='player-view-modal-select-column'>
                {translate('selectNewTeam', userConfig?.language)}
              </div>
            )}

            <div className='player-view-modal-select-column'>
              {translate('selectNewPosition', userConfig?.language)}
            </div>
          </div>

          <div className='player-view-modal-select-row' style={{ marginTop: 15 }}>
            {showTeamDropDown && (
              <div className='player-view-modal-select-column'>
                <DropDown
                  id={'player-view-modal-team-select'}
                  dropDownOptions={teams ? Object.keys(teams) : []}
                  selectedOptions={selectedTeam}
                  setSelectedOptions={setSelectedTeam}
                  isDropDownExpanded={isTeamDropDownExpanded}
                  setIsDropDownExpanded={setIsTeamDropDownExpanded}
                  defaultDropDownText={'selectTeam'}
                  defaultDropDownTextColor={'#000000'}
                  marginBetweenOptions={0}
                  maxHeight={'56vh'}
                  disabledOptions={teamsToDisable}
                />
              </div>
            )}

            <div className='player-view-modal-select-column'>
              <DropDown
                id={'player-view-modal-position-select'}
                dropDownOptions={positionOptions}
                selectedOptions={selectedPosition}
                setSelectedOptions={setSelectedPosition}
                isDropDownExpanded={isPositionDropDownExpanded}
                setIsDropDownExpanded={setIsPositionDropDownExpanded}
                defaultDropDownText={'selectPosition'}
                defaultDropDownTextColor={'#000000'}
                marginBetweenOptions={0}
                maxHeight={'56vh'}
              />
            </div>
          </div>
        </div>

        <div
          className='modal-content-section'
          style={{ maxHeight: 160, minHeight: 120 }}
        >
          <TextButton
            onClick={handleAddOrMovePlayer}
            text={buttonText}
            icon={isEditStatusView ? <ArrowRightAltIcon style={{ fontSize: 22 }} /> : <AddIcon style={{ fontSize: 22 }} />}
            disabled={isAddOrMoveButtonDisabled}
          />

          {isEditStatusView && <div className='modal-divider' />}
          {isEditStatusView && (
            <TextButton
              onClick={handleRemovePlayer}
              text={translate(squadId ? 'removePlayerFromSquad' : 'removePlayer', userConfig?.language)}
              icon={<PersonRemoveIcon style={{ fontSize: 22 }} />}
              disabled={isRemoveButtonDisabled}
            />
          )}
        </div>
      </div>

    </div>
  );
};
