import '../documents.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { teamsState } from '../../../recoil/atoms/teamsState';
import { squadsState } from '../../../recoil/atoms/squadsState';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';
import { trackEvent } from '../../../services/server/analytics/trackEvent';

import { translate } from '../../../../common/language/translations';
import { getClubColor } from '../../../static/clubConfigs';
import { StringToAnyMap } from '../../../types';
import { Toggle } from '../../controls/input/Toggle';
import { staticTeamKeys } from '../../../static/propertyValues';
import { getTeamIcon } from '../../../utils/iconUtils';
import { dateStringToLocaleFormat } from '../../../utils/dateUtils';


interface ExistingArchivedTeamsProps {
  archivedTeams: StringToAnyMap[];
  archivedTeamSelected: StringToAnyMap | undefined;
  handleArchivedTeamClicked: (archivedTeam: StringToAnyMap | undefined) => void;
}


export const ExistingArchivedTeams: React.FC<ExistingArchivedTeamsProps> = ({ archivedTeams, archivedTeamSelected, handleArchivedTeamClicked }) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);

  const teams = useRecoilValue(teamsState);
  const squads = useRecoilValue(squadsState);

  const [showDeletedArchivedTeams, setShowDeletedArchivedTeams] = useState(false);


  const handleSelectArchivedTeamAndTrackEvent = (archivedTeam: StringToAnyMap) => {
    if (archivedTeam.id === archivedTeamSelected?.id) {
      handleArchivedTeamClicked(undefined);
    }
    else {
      trackEvent('ArchivedTeamOrSquadViewed', {}, currentUser, 'user');
      handleArchivedTeamClicked(archivedTeam);
    }
  };


  const getExistingArchivedTeamClassName = (archivedTeam: StringToAnyMap) => {
    let baseClassName = 'existing-document existing-archived-team';

    if (archivedTeam.isDeleted) {
      baseClassName += ' existing-document-deleted';
    }
    if (archivedTeam.id === archivedTeamSelected?.id) {
      baseClassName += ' existing-document-selected';
    }

    return baseClassName;
  };


  const getArchivedTeamTitle = (archivedTeam: StringToAnyMap) => {
    if (!userConfig) return;

    const teamOrSquadId = archivedTeam['teamOrSquadId'];
    if (staticTeamKeys.includes(teamOrSquadId)) {
      return translate(teamOrSquadId, userConfig.language);
    }

    if (teams && teamOrSquadId in teams) {
      return teams[teamOrSquadId]['name'];
    }

    if (squads && teamOrSquadId in squads) {
      return squads[teamOrSquadId]['name'];
    }

    return archivedTeam['name'];
  };


  const clubColor = getClubColor(userConfig?.club ?? '');

  return (
    <div className='existing-documents-container'>

      <div className='existing-documents-title'>
        {translate('allArchivedTeams', userConfig?.language)}
      </div>

      <div className='existing-documents-divider existing-documents-top-divider' />

      <div className='existing-documents-section existing-documents-section-single-toggle'>
        {archivedTeams.length === 0 && (
          <div className='no-existing-documents-title'>
            {translate('noArchivedTeams', userConfig?.language)}
          </div>
        )}
        {archivedTeams.map((archivedTeam: StringToAnyMap) => {
          if (!showDeletedArchivedTeams && archivedTeam['isDeleted']) return null;

          const title = getArchivedTeamTitle(archivedTeam);

          return (
            <div
              key={archivedTeam.id}
              className={getExistingArchivedTeamClassName(archivedTeam)}
              onClick={() => handleSelectArchivedTeamAndTrackEvent(archivedTeam)}
            >
              {getTeamIcon(archivedTeam['teamOrSquadId'], 22, '#ffffff', archivedTeam['isSquad'])}

              <div className='existing-document-vertical-divider' style={{ backgroundColor: clubColor }} />

              <div className='existing-document-info'>
                <div className='existing-document-row'>
                  <div className='existing-document-title' style={{ fontSize: title.length > 20 ? 10 : title.length > 16 ? 11 : title.length > 12 ? 12 : 13 }}>
                    {title}
                  </div>
                  <div className='existing-archived-teams-date'>
                    {dateStringToLocaleFormat(archivedTeam['date'].split('T')[0])}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className='existing-documents-divider existing-documents-bottom-divider-single-toggle' />

      <div className='existing-document-toggle-container existing-document-toggle-bottom-container'>
        <div className='existing-document-toggle-section' style={{ width: 165 }}>
          <div className='existing-document-toggle-title'>
            {translate('showDeletedArchivedTeams', userConfig?.language)}
          </div>
          <div className='existing-document-toggle'>
            <Toggle isToggled={showDeletedArchivedTeams} setIsToggled={setShowDeletedArchivedTeams} />
          </div>
        </div>
      </div>

    </div>
  );
};
