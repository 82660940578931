import '../tables.css';

import { useEffect, useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';
import { clubScopesState } from '../../../recoil/atoms/clubScopesState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { useSetAndTrackSelectedPlayerState } from '../../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { playerOverviewsState } from '../../../recoil/atoms/playerOverviewsState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';

import { PlayerOverviews, PositionGroupClubIteration } from '../../../types';
import { getPlayerSeasonTableDataColumnGroups, playerSeasonTableInfoColumns } from './playerSeasonColumns';
import { getClubSecondaryColor } from '../../../static/clubConfigs';
import { debounce } from '../../../utils/utils';
import { playerSeasonTableChapterSize, playerSeasonTablePageSize } from '../../../views/scouting/Scouting';
import { PlayerInfoCell, PlayerPaginationCell, PlayerSeasonTableMinutesCell } from '../cells';
import { translate } from '../../../../common/language/translations';
import { getDisplayPositions } from '../../../utils/playerUtils';
import { positionGroupToPositions } from '../../../static/propertyValues';
import { metricToDisplayInfo } from '../../../static/playerMetrics';
import { Toggle } from '../../controls/input/Toggle';
import { updateSeasonStatsToggles } from '../../../services/firestore/userSettings';


interface PlayerSeasonTableProps {
  data: PositionGroupClubIteration[];
  additionalPlayerOverviews: PlayerOverviews;

  isLoading: boolean;
  handleSearchButtonPressed: (isUserInitiated: boolean, isNewSearch: boolean, nextChapter?: number) => Promise<void>;
  currentModuloPage: number;
  currentChapter: number;
  totalHits: number;
  handleChangeCurrentChapter: (isIncrement: boolean) => void;

  isGoalkeeperSelected: boolean;
  selectedOrderBy: string | undefined;
  selectedRoles: string[];
}

export const PlayerSeasonTable: React.FC<PlayerSeasonTableProps> = ({
  data,
  additionalPlayerOverviews,

  isLoading,
  handleSearchButtonPressed,
  currentModuloPage,
  currentChapter,
  totalHits,
  handleChangeCurrentChapter,

  isGoalkeeperSelected,
  selectedOrderBy,
  selectedRoles,
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);
  const clubSettings = useRecoilValue(clubSettingsState);
  const clubScopes = useRecoilValue(clubScopesState);

  const playerOverviews = useRecoilValue(playerOverviewsState);
  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();

  const { openTextModal, openRoleInfoModal } = useOpenGlobalModal();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const seasonStatsToggles = useMemo(() => userSettings?.seasonStatsToggles ?? {}, [userSettings?.seasonStatsToggles]);
  const roleConfigs = useMemo(() => clubSettings?.roleConfigs ?? {}, [clubSettings?.roleConfigs]);
  const selectedRolesInternal = useMemo(() => selectedRoles, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps
  const dataColumnGroups = useMemo(
    () => {
      return getPlayerSeasonTableDataColumnGroups(
        selectedRolesInternal,
        roleConfigs,
        isGoalkeeperSelected,
        clubScopes?.hasSkillcorner ?? false,
        seasonStatsToggles,
        userConfig?.language ?? 'en',
        userConfig?.isColorBlind ?? false,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedRolesInternal,
      roleConfigs,
      isGoalkeeperSelected,
      clubScopes?.hasSkillcorner,
      seasonStatsToggles,
      userConfig?.isColorBlind,
      userConfig?.language,
    ]
  );


  const handleRowClick = (playerSeason: PositionGroupClubIteration) => {
    const initialPositionGroup = selectedRoles && selectedRoles.length > 0 && clubSettings && clubSettings.roleConfigs[selectedRoles[0]]
      ? clubSettings.roleConfigs[selectedRoles[0]].positionGroup
      : undefined;

    setAndTrackSelectedPlayerState({
      id: playerSeason.player_id,
      fullname: playerSeason.fullname,
      playerOverview: playerOverviews[playerSeason.player_id] ?? additionalPlayerOverviews[playerSeason.player_id],
      initialPositionGroup: initialPositionGroup,
      initialClubIteration: playerSeason.club.id + ',' + playerSeason.iteration_id,
    });
  };


  const checkScrollPosition = async () => {
    if (!tableContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
    const scrollPosition = scrollTop / (scrollHeight - clientHeight);
    const threshold = 0.4 + currentModuloPage * 0.1;

    const isMoreDataToFetch = ((currentChapter * playerSeasonTablePageSize * playerSeasonTableChapterSize) + data.length) < totalHits;

    if (scrollPosition > threshold && !isLoading && currentModuloPage < 4 && isMoreDataToFetch) {
      await handleSearchButtonPressed(false, false);
    }
  };


  useEffect(() => {
    if (playerSeasonTableChapterSize > 1) {
      const [handleScroll, cancelHandleScroll] = debounce(() => checkScrollPosition(), 250);

      const tableContainer = tableContainerRef.current;
      if (tableContainer) {
        tableContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (tableContainer) {
          tableContainer.removeEventListener('scroll', handleScroll);
        }
        cancelHandleScroll();
      };
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps


  const playerSeasonTableStickyColumnWidth = 375;


  const hitsPerChapter = playerSeasonTablePageSize * playerSeasonTableChapterSize;
  const positionGroup = selectedRolesInternal && selectedRolesInternal.length > 0 && roleConfigs[selectedRolesInternal[0]]
    ? roleConfigs[selectedRolesInternal[0]].positionGroup
    : 'overall';


  return (
    <div
      ref={tableContainerRef}
      className='table-container player-table-container'
    >

      {/* Header */}
      <div className='table-header player-table-header player-table-header-background'>


        {/* Player column header */}
        <div
          className='table-sticky-left player-table-header-background player-table-header-cell-with-border'
          style={{ width: playerSeasonTableStickyColumnWidth }}
        >
          <PlayerPaginationCell
            totalHits={totalHits}
            currentChapter={currentChapter}
            hitsPerChapter={hitsPerChapter}
            handleChangeCurrentChapter={handleChangeCurrentChapter}
            paginationSectionPaddingRight={16}
            language={userConfig?.language}
          />
        </div>


        {/* Minutes column header */}
        <div
          className='table-sticky-left table-header-group player-table-header-background player-table-header-cell-with-border'
          style={{ width: 140, left: playerSeasonTableStickyColumnWidth }}
        >
          {translate('dataBasis', userConfig?.language)}

          <div className='table-header-group-row' style={{ fontSize: 10 }}>
            {translate('basedOn<positions>', userConfig?.language) + ' '}
            {positionGroup !== 'overall'
              ? getDisplayPositions(positionGroupToPositions[positionGroup], userConfig?.language)
              : translate('allPositions', userConfig?.language, true)}
          </div>
        </div>

        {/* Info column headers */}
        <div className='table-header-group player-table-header-cell-with-border'>
          {translate('info', userConfig?.language)}

          <div className='table-header-group-row'>
            {playerSeasonTableInfoColumns.map(column => {

              return (
                <div
                  key={column.key}
                  className='table-cell'
                  style={{ width: column.width, }}
                >
                  {translate(column.key, userConfig?.language)}
                </div>
              );
            })}
          </div>
        </div>

        {Object.entries(dataColumnGroups).map(([metricGroupKey, dataColumns]) => {

          const onClick = () => openTextModal(
            metricToDisplayInfo[metricGroupKey].name[userConfig?.language ?? 'en'],
            metricToDisplayInfo[metricGroupKey].description[userConfig?.language ?? 'en'],
            { infoType: 'metric', titleKey: metricGroupKey }
          );

          return (
            <div
              key={metricGroupKey}
              className='table-header-group player-table-header-cell-with-border'
            >
              <div className='flex-row align-center'>
                <div className='table-header-cell-clickable' onClick={onClick}>
                  {metricToDisplayInfo[metricGroupKey].name[userConfig?.language ?? 'en']}
                </div>

                {userConfig && metricGroupKey !== 'ratings' && metricGroupKey !== 'obv' && (
                  <div
                    className='table-header-toggle'
                    title={translate(seasonStatsToggles[metricGroupKey] ? 'showNormalizedValues' : 'showActualValues', userConfig?.language)}
                  >
                    <Toggle
                      isToggled={seasonStatsToggles[metricGroupKey]}
                      setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                        { [metricGroupKey]: value },
                        userConfig.email,
                        userConfig.club,
                        currentUser
                      )}
                      isSmall={true}
                    />
                  </div>
                )}
              </div>

              <div className='table-header-group-row'>
                {dataColumns.map(column => {

                  const roleId = column.roleId;
                  const onClick = roleId
                    ? () => openRoleInfoModal(roleConfigs[roleId])
                    : () => openTextModal(
                      metricToDisplayInfo[column.key].name[userConfig?.language ?? 'en'],
                      metricToDisplayInfo[column.key].description[userConfig?.language ?? 'en'],
                      { infoType: 'metric', titleKey: column.key }
                    );

                  const isOrderedBy = selectedOrderBy && selectedOrderBy === column.key;

                  return (
                    <div
                      key={column.key}
                      className='table-cell'
                      style={{
                        width: column.width,
                        color: isOrderedBy ? getClubSecondaryColor(userConfig?.club ?? '') : undefined,
                        fontWeight: isOrderedBy ? 600 : undefined,
                      }}
                    >
                      <div className='table-header-cell-clickable' onClick={onClick}>
                        {column.metricName}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>


      {/* Body */}
      <div className='player-table-body'>
        {data.map((row, rowIndex) => {
          return (
            <div
              key={rowIndex}
              className={'table-row player-table-row' + (rowIndex % 2 === 0 ? ' player-table-row-even' : ' player-table-row-odd')}
              onClick={() => handleRowClick(row)}
            >
              {/* Player column */}
              <div
                className={
                  'table-sticky-left player-table-sticky-cell player-table-body-cell-with-border'
                  + (rowIndex % 2 === 0 ? ' player-table-sticky-cell-even' : ' player-table-sticky-cell-odd')
                }
                style={{ width: playerSeasonTableStickyColumnWidth }}
              >
                <PlayerInfoCell
                  positionGroupClubIteration={row}
                  infoCellColumnWidth={210}
                  language={userConfig?.language}
                />
              </div>

              {/* Minutes column */}
              <div
                className={
                  'table-sticky-left player-table-sticky-cell player-table-body-cell-with-border'
                  + (rowIndex % 2 === 0 ? ' player-table-sticky-cell-even' : ' player-table-sticky-cell-odd')
                }
                style={{ width: 140, left: playerSeasonTableStickyColumnWidth }}
              >
                <PlayerSeasonTableMinutesCell
                  row={row}
                />
              </div>

              {/* Info columns */}
              <div className='flex-row player-table-body-cell-with-border'>
                {playerSeasonTableInfoColumns.map(column => {
                  return (
                    <div
                      key={column.key}
                      className='table-cell'
                      style={{ width: column.width }}
                    >
                      {column.cell({ row, language: userConfig?.language })}
                    </div>
                  );
                })}
              </div>

              {/* Data columns */}
              {Object.entries(dataColumnGroups).map(([metricGroupKey, dataColumns]) => {
                return (
                  <div className='flex-row player-table-body-cell-with-border' key={metricGroupKey}>
                    {dataColumns.map(column => {
                      return (
                        <div
                          key={column.key}
                          className='table-cell'
                          style={{ width: column.width }}
                        >
                          {column.cell({ row, language: userConfig?.language, positionGroup: positionGroup })}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
