
import { RoleConfigMap } from '../../../types';
import { dateStringToLocaleFormat } from '../../../utils/dateUtils';
import { getFormIcon, getPlayingTimeIcon } from '../../../utils/iconUtils';
import { PlayerOverviewWithAlertProperties } from '../../../views/scouting/alerts/Alerts';
import { AlertTableExcludeCell, AlertTableExcludedCell, ConfidenceCell, IndexCell, PositionCell } from '../cells';
import { getRoleColumnWidth } from '../utils';


interface AlertPlayerTableColumnCellProps {
  row: PlayerOverviewWithAlertProperties;
  language?: string;
  positionGroup?: string;
  excludePlayer?: (playerId: number, allAlerts: boolean, monthsExcluded: number) => void;
  undoExcludePlayer?: (playerOverview: PlayerOverviewWithAlertProperties, allAlerts: boolean) => void;
}


interface AlertPlayerTableColumn {
  key: string;
  width: number;

  orderByKey?: string;
  textModalInfoKey?: string;
  roleId?: string;

  cell: (props: AlertPlayerTableColumnCellProps) => JSX.Element;
}


export const alertTableInfoColumnsWidth = 365;
export const alertTableInfoColumns: AlertPlayerTableColumn[] = [
  {
    key: 'age',
    width: 50,
    orderByKey: 'age',
    cell: ({ row }: AlertPlayerTableColumnCellProps) => (
      <div className='table-cell'>
        {row.age ?? '-'}
      </div>
    ),
  },
  {
    key: 'contract',
    width: 90,
    cell: ({ row }: AlertPlayerTableColumnCellProps) => (
      <div className='table-cell'>
        {dateStringToLocaleFormat(row.contract_expiration)}
      </div>
    ),
  },
  {
    key: 'position',
    width: 85,
    cell: ({ row, language }: AlertPlayerTableColumnCellProps) => (
      <PositionCell playerData={row} language={language} maxPositions={2} />
    ),
  },
  {
    key: 'form',
    width: 60,
    textModalInfoKey: 'form',
    cell: ({ row }: AlertPlayerTableColumnCellProps) => (
      <div className='table-cell'>
        {getFormIcon(row.form_status, 18, 'dark', 43)}
      </div>
    ),
  },
  {
    key: 'playingTime',
    width: 80,
    textModalInfoKey: 'playingTime',
    cell: ({ row }: AlertPlayerTableColumnCellProps) => (
      <div className='table-cell'>
        {getPlayingTimeIcon(row.availability_status, 18, 'dark')}
      </div>
    ),
  },
];


export const alertTableStaticDataColumnsWidth = 170;
export const getAlertTableDataColumns = (selectedRoles: string[] | undefined, roleConfigs: RoleConfigMap) => {
  const dataColumns: AlertPlayerTableColumn[] = [
    {
      key: 'skill_index',
      width: 90,
      orderByKey: 'skillIndex',
      cell: ({ row, positionGroup }: AlertPlayerTableColumnCellProps) => (
        <IndexCell playerOverview={row} property='skill_index' positionGroup={positionGroup ?? 'overall'} />
      ),
    },
  ];

  if (selectedRoles) {
    selectedRoles.forEach(roleId => {
      dataColumns.push({
        key: roleId,
        width: getRoleColumnWidth(roleConfigs[roleId].name),
        roleId: roleId,
        orderByKey: roleId,
        cell: ({ row, positionGroup }: AlertPlayerTableColumnCellProps) => (
          <IndexCell playerOverview={row} property={roleId} positionGroup={positionGroup ?? 'overall'} />
        ),
      });
    });
  }

  dataColumns.push({
    key: 'confidence',
    width: 80,
    cell: ({ row, positionGroup }: AlertPlayerTableColumnCellProps) => (
      <div className='table-cell'>
        <ConfidenceCell playerOverview={row} positionGroup={positionGroup ?? 'overall'} />
      </div>
    ),
  });

  return dataColumns;
};


export const alertTableActionColumnWidth = 140;
export const alertTablePadding = 15;
export const getAlertTableActionColumn = (isExcludedPlayersView: boolean): AlertPlayerTableColumn => {
  if (isExcludedPlayersView) {
    return {
      key: 'undoAll',
      width: alertTableActionColumnWidth + alertTablePadding,
      cell: ({ row, language, undoExcludePlayer }: AlertPlayerTableColumnCellProps) => (
        <div className='table-cell' style={{ paddingRight: alertTablePadding }}>
          <AlertTableExcludedCell row={row} language={language} undoExcludePlayer={undoExcludePlayer} />
        </div>
      ),
    };
  }

  return {
    key: 'removeAll',
    width: alertTableActionColumnWidth + alertTablePadding,
    cell: ({ row, language, excludePlayer }: AlertPlayerTableColumnCellProps) => (
      <div className='table-cell' style={{ paddingRight: alertTablePadding }}>
        <AlertTableExcludeCell row={row} language={language} excludePlayer={excludePlayer} />
      </div>
    ),
  };
};
