import '../tables.css';

import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { StringToAnyMap } from '../../../types';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { getNavigationWidth } from '../../controls/navigation/Navigation';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { getTeamTableColumns, teamTableColumnsWidth, teamTablePadding } from './teamColumns';
import { translate } from '../../../../common/language/translations';
import { TeamTableTeamCell } from '../cells';


interface TeamTableProps {
  data: StringToAnyMap[];
  isAdvancedTeamDataToggled: boolean;
}


export const TeamTable: React.FC<TeamTableProps> = ({ data, isAdvancedTeamDataToggled }) => {

  const userConfig = useRecoilValue(userConfigState);
  const clubSettings = useRecoilValue(clubSettingsState);

  const { width } = useWindowSize();

  const [sortedData, setSortedData] = useState<StringToAnyMap[]>([]);
  const [currentSortBy, setCurrentSortBy] = useState<StringToAnyMap | undefined>(undefined);


  useEffect(() => {
    setSortedData(data);
    setCurrentSortBy(undefined);
  }, [isAdvancedTeamDataToggled, data]);


  const teamColumns = useMemo(
    () => {
      return getTeamTableColumns(isAdvancedTeamDataToggled, userConfig?.language);
    },
    [
      isAdvancedTeamDataToggled,
      userConfig?.language
    ]
  );


  const handleHeaderCellClick = (column: StringToAnyMap) => {
    // either (1) first sort on a new column, (2) reverse the order of the current column, or (3) reset sorting

    // (1) First sort on a new column
    if (!currentSortBy || currentSortBy.property !== column.property) {
      const sortedData = [...data].sort((a, b) => {
        let aValue = a[column.property];
        let bValue = b[column.property];

        if (column.property === 'u21MinutesPerMinute') {
          aValue = Number(aValue.replace('%', ''));
          bValue = Number(bValue.replace('%', ''));
        }

        if (aValue > bValue) return -1;
        if (aValue < bValue) return 1;

        return 0;
      });
      setSortedData(sortedData);
      setCurrentSortBy({ property: column.property, desc: true });
      return;
    }

    // (2) Reverse the order of the current column
    if (currentSortBy.desc) {
      const sortedData = [...data].sort((a, b) => {
        let aValue = a[column.property];
        let bValue = b[column.property];

        if (column.property === 'u21MinutesPerMinute') {
          aValue = Number(aValue.replace('%', ''));
          bValue = Number(bValue.replace('%', ''));
        }

        if (aValue > bValue) return 1;
        if (aValue < bValue) return -1;

        return 0;
      });
      setSortedData(sortedData);
      setCurrentSortBy({ property: column.property, desc: false });
      return;
    }

    // (3) Reset sorting
    const unsortedData = [...data];
    setSortedData(unsortedData);
    setCurrentSortBy(undefined);
  };


  const homeContainerWidth = width - 40 - getNavigationWidth(userConfig);
  const tableWidth = (homeContainerWidth * 0.6) - 20;

  const teamTableStickyColumnWidth = 160;
  const teamTableNonStickyWidth = tableWidth - teamTableStickyColumnWidth;
  const teamTableNonStickyMinWidth = teamTableColumnsWidth + teamTablePadding;
  const widthUnit = Math.max(teamTableNonStickyWidth / teamTableNonStickyMinWidth, 1);


  return (
    <div
      className='table-container team-table-container'
      style={{ overflowX: widthUnit <= 1 ? 'auto' : 'hidden' }}
    >

      {/* Header */}
      <div className='table-header team-table-header team-table-header-background'>

        <div
          className='table-sticky-left team-table-header-background table-cell'
          style={{ width: teamTableStickyColumnWidth, justifyContent: 'left', paddingLeft: 12, boxSizing: 'border-box' }}
        >
          {translate('team', userConfig?.language)}
        </div>

        {teamColumns.map(column => {

          const paddingRight = (column.property === 'points' || column.property === 'xPts') ? teamTablePadding : 0;

          return (
            <div
              key={column.property}
              className='table-cell team-table-header-cell'
              style={{ width: column.width * widthUnit, cursor: 'pointer' }}
              onClick={() => handleHeaderCellClick(column)}
            >
              <div
                className='flex-row align-center'
                style={{ paddingRight: paddingRight, position: 'relative' }}
                title={column.title}
              >
                {column.header}

                {currentSortBy && currentSortBy.property === column.property && (
                  <div style={{ position: 'absolute', right: paddingRight - 22 }}>
                    {currentSortBy.desc
                      ? <KeyboardArrowDownIcon style={{ fontSize: 21, marginBottom: -2 }} />
                      : <KeyboardArrowUpIcon style={{ fontSize: 21, marginBottom: -2 }} />
                    }
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>


      {/* Body */}
      <div>
        {sortedData.map((row, rowIndex) => {

          return (
            <div
              key={rowIndex}
              className={
                'table-row no-hover team-table-row'
                + (rowIndex % 2 === 0 ? ' season-table-row-even' : ' season-table-row-odd')
                + (row.club_id === clubSettings?.clubId ? ' season-table-row-selected' : '')
              }
              style={{ height: (80 / data.length) + 'vh' }}
            >

              <div
                className={
                  'table-sticky-left player-table-header-cell-with-border'
                  + (rowIndex % 2 === 0 ? ' season-table-cell-even' : ' season-table-cell-odd')
                }
                style={{ width: teamTableStickyColumnWidth }}
              >
                <TeamTableTeamCell row={row} index={rowIndex} />
              </div>

              {teamColumns.map(column => {
                return (
                  <div
                    key={column.property}
                    className='table-cell'
                    style={{ width: column.width * widthUnit }}
                  >
                    {column.cell(row)}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
